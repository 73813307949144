import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Get, Put } from "utils/axios";

import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { getBranches, getInvoicingBranches } from "actions/branch";
import { getCountryDictionary } from "actions/dictionary";
import { getAgencies } from "actions/agency";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";

import {
  types,
  categories,
  statuses,
  measurements,
  occupancies,
  furnishing_statuses,
  tenure_types,
} from "dictionary/subsales";

const searchParams = ({ countries }) => [
  {
    label: "Property Name",
    value: "property_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Type",
    value: "property_type_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Listing Category",
    value: "category_id_eq",
    type: "select",
    param: "",
    options: categories,
  },
  {
    label: "Listing Code",
    value: "code_cont",
    type: "input",
    param: "",
  },
  {
    label: "Country",
    value: "country_id_eq",
    type: "select",
    param: "",
    options: countries,
  },
  {
    label: "State",
    value: "state_id_eq",
    type: "select",
    param: "",
    options: [],
  },
  {
    label: "Township",
    value: "township_cont",
    type: "typeahead",
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    containerStyle: {
      padding: "1px 10px",
    },
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Url Slug",
    value: "slug_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: "0", label: "Draft" },
      { value: "1", label: "Active" },
      { value: "2", label: "Inactive" },
      { value: "4", label: "Expired" },
      { value: "5", label: "Deleted" },
    ],
  },
  {
    label: "Listing Type",
    value: "type_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: "1", label: "Sale" },
      { value: "2", label: "Rental" },
    ],
  },
  {
    label: "Favourite",
    value: "is_favourite_true",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
  },
];

const SubsalesHOC = (WrappedComponent) => {
  class SubsalesWrappedComponent extends Component {
    state = {
      showLoadingModal: false,
      showFavouriteLoading: false,
      showEditModal: false,
      showViewModal: false,

      descriptionTab: "Eng", // 1. Eng 2. Zh
      requests: [],
      townshipArray: [],
      propertyOptions: [],
      subAreaArray: [],

      subsalesTableHeader: [
        {
          label: "Code",
          value: "code",
        },
        {
          label: "Agent Name",
          value: "agent_full_name",
          width: "130%",
        },
        {
          label: "Email",
          value: "agent_email",
          width: "130%",
        },
        {
          label: "Type",
          value: "type",
          width: "70%",
        },
        {
          label: "Township",
          value: "township",
        },
        {
          label: "Property Name",
          value: "property_name",
        },
        {
          label: "Property Type",
          value: "property",
        },
        {
          label: "Status",
          value: "status",
          width: "70%",
        },
      ],
      tmpSubsalesCurrentUser: {},
      subsalesEntryCreationProps: {},
      showSubsaleInfoLoadingModal: false,
      showSubsalesListingPreviewModal: false,
      showDeleteModal: false,
      searchParams: searchParams(this.props.data.dictionaryReducer),
      subsalesPages: null,
      listingPreviewType: "IQI",
      query: "",

      initialRecommendation: [],
      fullRecommendationList: [],
    };

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeSubsalesHOC = (val, context) => this.setState({ [context]: val });

    // get subsales list
    getSubsalesCurrentUser = (page, search) => {
      this.setState({ query: search });
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/sub_sales?${search}page=${page}`,
        this.getSubsalesListingSuccess,
        this.getSubsalesListingError,
        this.load,
      );
    };
    getSubsalesListingSuccess = (payload) => {
      let tmpTotalPages = [];
      let tmpSubsalesCurrentUser = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpType = _.find(types, { id: item.type_id });
          let tmpCategory = _.find(categories, { id: item.category_id });
          let tempStatus = _.find(statuses, { id: item.status_id });
          let tmpCatGroupPropertyType = {};
          if (tmpCategory) {
            tmpCategory.group_types.map((child) => {
              return child.children.map((childItem) => {
                if (childItem.id === item.property_type_id) {
                  tmpCatGroupPropertyType = childItem;
                }
              });
            });
          }
          let tempFurnishing = _.find(furnishing_statuses, {
            id: item.furnishing_status_id,
          });
          let tempMeasurement = _.find(measurements, {
            id: item.measurement_id,
          });
          let tempOccupancy = _.find(occupancies, { id: item.occupancy_id });
          let tempTenure = _.find(tenure_types, { id: item.tenure_id });

          let featureArray = [
            {
              label: "Occupancy",
              value:
                tempOccupancy && tempOccupancy.name.toLowerCase() !== "unknown"
                  ? tempOccupancy.name
                  : "N/A",
            },
            {
              label: "Furnishing",
              value:
                tempFurnishing &&
                tempFurnishing.name.toLowerCase() !== "unknown"
                  ? tempFurnishing.name
                  : "N/A",
            },
            {
              label: "Tenure",
              value:
                tempTenure && tempTenure.name.toLowerCase() !== "unknown"
                  ? tempTenure.name
                  : "N/A",
            },
          ];

          let data = {
            ...item,
            updated_on: item.updated_on
              ? Moment(item.updated_on, "DD-MM-YYYY").format("DD MMM YYYY")
              : "N/A",
            featureArray: featureArray,
            type: tmpType && tmpType.name ? tmpType.name : "N/A",
            property:
              tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
                ? tmpCatGroupPropertyType.name
                : "N/A",
            property_name: item.property_name || "N/A",
            address: item.address || "N/A",
            measurement:
              tempMeasurement && tempMeasurement.name
                ? tempMeasurement.name
                : "N/A",
            status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
            category:
              tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          };
          return tmpSubsalesCurrentUser.push(data);
        });
      payload.data = tmpSubsalesCurrentUser;
      this.setState({
        tmpSubsalesCurrentUser: payload,
        subsalesPages: tmpTotalPages,
      });
    };
    getSubsalesListingError = (error) => requestError(error);

    // get township based on state
    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) => {
      let temp = [];
      let tempSearch = _.cloneDeep(this.state.searchParams);
      let townShipIndex = _.findIndex(tempSearch, { value: "township_cont" });
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let tempObj = {
            id: item.id,
            value: item.name,
            name: item.name,
            label: item.name,
          };
          temp.push(tempObj);
        });
      tempSearch[townShipIndex].options = temp;
      this.setState({ searchParams: tempSearch, townshipArray: temp });
    };
    getSelectedTownShipError = (error) => requestError(error);

    // get selected listing
    getSelectedSubsales = (val) =>
      Get(
        `/admin/sub_sales/${val}`,
        this.getSelectedSubsalesSuccess,
        this.getSelectedSubsalesError,
        (param) =>
          this.setState({
            showLoadingModal: param,
          }),
      );
    getSelectedSubsalesSuccess = (payload) => {
      let tmp = _.cloneDeep(payload);
      this.getSelectedTownShip(payload.state_id);
      this.getSelectedSubArea(payload.township);

      let tmpType = _.find(types, { id: tmp.type_id });
      let tmpCategory = _.find(categories, { id: tmp.category_id });
      let tempStatus = _.find(statuses, { id: tmp.status_id });
      let tmpCatGroupPropertyType = {};
      if (tmpCategory) {
        tmpCategory.group_types.map((child) => {
          return child.children.map((childItem) => {
            if (childItem.id === tmp.property_type_id) {
              tmpCatGroupPropertyType = childItem;
            }
          });
        });
      }
      let tempFurnishing = _.find(furnishing_statuses, {
        id: tmp.furnishing_status_id,
      });
      let tempMeasurement = _.find(measurements, { id: tmp.measurement_id });
      let tempOccupancy = _.find(occupancies, { id: tmp.occupancy_id });
      let tempTenure = _.find(tenure_types, { id: tmp.tenure_id });

      let featureArray = [
        {
          label: "Occupancy",
          value:
            tempOccupancy && tempOccupancy.name.toLowerCase() !== "unknown"
              ? tempOccupancy.name
              : "N/A",
        },
        {
          label: "Furnishing",
          value:
            tempFurnishing && tempFurnishing.name.toLowerCase() !== "unknown"
              ? tempFurnishing.name
              : "N/A",
        },
        {
          label: "Tenure",
          value:
            tempTenure && tempTenure.name.toLowerCase() !== "unknown"
              ? tempTenure.name
              : "N/A",
        },
      ];

      let facilitiesArray = [
        { label: "All Day Security", value: tmp.all_day_security },
        { label: "Business Centre", value: tmp.business_centre },
        { label: "Parking", value: tmp.parking },
        { label: "Gymnasium", value: tmp.gymnasium },
        { label: "Jogging Track", value: tmp.jogging_track },
        { label: "Playground", value: tmp.playground },
        { label: "Cafeteria", value: tmp.cafetria },
        { label: "Club House", value: tmp.club_house },
        { label: "Jacuzzi", value: tmp.jacuzzi },
        { label: "Mini Market", value: tmp.mini_market },
        { label: "Nursery", value: tmp.nursery },
        { label: "Salon", value: tmp.salon },
        { label: "Sauna", value: tmp.sauna },
        { label: "Squash Court", value: tmp.squash_court },
        { label: "Tennis Court", value: tmp.tennis_court },
        { label: "Swimming Pool", value: tmp.swimming_pool },
        { label: "Wading Pool", value: tmp.wading_pool },
        { label: "BBQ", value: tmp.bbq },
      ];

      let tempRcc = _.map(payload.recommended_agents_info, (item) => ({
        ...item,
        cobrokeRequestId: item.co_broke_requests.id,
        status: item.co_broke_requests.status,
        status_id: item.co_broke_requests.status_id,
        last_transacted: Moment(item.last_transacted).format("DD MMM YYYY"),
        last_transacted_unix: item.last_transacted
          ? Moment(item.last_transacted).unix()
          : 0,
      }));

      this.setState({
        initialRecommendation: tempRcc.slice(0, 10),
        fullRecommendationList: tempRcc,
        subsalesEntryCreationProps: {
          ...tmp,
          car_parks: tmp.car_parks || "N/A",
          bedrooms: tmp.bedrooms || "N/A",
          bathrooms: tmp.bathrooms || "N/A",
          built_up: tmp.built_up,
          land_area: tmp.land_area,
          facilities: facilitiesArray,
          featureArray: featureArray,
          type: tmpType && tmpType.name ? tmpType.name : "N/A",
          property:
            tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
              ? tmpCatGroupPropertyType.name
              : "N/A",
          measurement:
            tempMeasurement && tempMeasurement.name
              ? tempMeasurement.name
              : "N/A",
          status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
          view_available_date: tmp.available_date
            ? Moment(tmp.available_date).format("DD MMM YYYY")
            : "N/A",
          updated_on: tmp.updated_on
            ? Moment(tmp.updated_on, "DD-MM-YYYY").format("DD MMM YYYY")
            : "N/A",
          category: tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",

          township: payload.township ? payload.township : "",
          sub_area: payload.sub_area ? payload.sub_area : "",
        },
      });
    };
    getSelectedSubsalesError = (error) => requestError(error);

    getSelectedSubArea = (val) =>
      Get(
        `/sub_areas?township_name=${val}`,
        this.getSelectedSubAreaSuccess,
        this.getSelectedSubAreaError,
        this.load,
      );
    getSelectedSubAreaSuccess = (payload) => {
      let temp = [];
      payload !== null &&
        payload.map((item) => {
          let tempObj = { value: item.name, id: item.id };
          temp.push(tempObj);
        });
      this.setState({ subAreaArray: temp });
    };
    getSelectedSubAreaError = (error) => requestError(error);

    toggleFavourite = (id) =>
      Put(
        `/sub_sales/${id}/favourite`,
        "",
        () => this.toggleFavouriteSuccess(id),
        this.toggleFavouriteError,
        (param) =>
          this.setState({
            showFavouriteLoading: param,
          }),
      );
    toggleFavouriteSuccess = (id) => {
      let tmpSubsalesArrays = _.cloneDeep(
        this.state.tmpSubsalesCurrentUser.data,
      );
      let tmpIndex = _.findIndex(tmpSubsalesArrays, { id: id });
      requestSuccess(
        !tmpSubsalesArrays[tmpIndex].is_favourite
          ? "Favourite has been added successfully."
          : "Favourite has been removed successfully.",
      );

      if (this.state.showViewModal) {
        this.getSelectedSubsales(id);
      }

      tmpSubsalesArrays[tmpIndex] = {
        ...tmpSubsalesArrays[tmpIndex],
        is_favourite: !tmpSubsalesArrays[tmpIndex].is_favourite,
      };

      this.setState({
        tmpSubsalesCurrentUser: {
          ...this.state.tmpSubsalesCurrentUser,
          data: tmpSubsalesArrays,
        },
      });
    };
    toggleFavouriteError = (error) => requestError(error);
    getStateLocation = (id) =>
      Get(
        `/sub_sales/get_buildings_list?state_id=${id}`,
        this.getStateLocationSuccess,
        this.getStateLocationError,
        this.load,
      );
    getStateLocationSuccess = (payload) =>
      this.setState({ propertyOptions: payload });
    getStateLocationError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            descriptionTab={this.state.descriptionTab}
            propertyOptions={this.state.propertyOptions}
            subAreaArray={this.state.subAreaArray}
            onLoadSubsales={this.state.showLoadingModal}
            onLoadFavourite={this.state.showFavouriteLoading}
            showEditModal={this.state.showEditModal}
            showViewModal={this.state.showViewModal}
            townshipArray={this.state.townshipArray}
            subsalesEntryCreationProps={this.state.subsalesEntryCreationProps}
            query={this.state.query}
            listingPreviewType={this.state.listingPreviewType}
            searchParams={this.state.searchParams}
            showSubsalesListingPreviewModal={
              this.state.showSubsalesListingPreviewModal
            }
            subsalesPages={this.state.subsalesPages}
            tmpSubsalesCurrentUser={this.state.tmpSubsalesCurrentUser}
            subsalesTableHeader={this.state.subsalesTableHeader}
            showDeleteModal={this.state.showDeleteModal}
            showSubsaleInfoLoadingModal={this.state.showSubsaleInfoLoadingModal}
            openSnackBar={this.state.openSnackBar}
            snackBarMessage={this.state.snackBarMessage}
            initialRecommendation={this.state.initialRecommendation}
            fullRecommendationList={this.state.fullRecommendationList}
            toggleFavourite={this.toggleFavourite}
            onChangeSubsalesHOC={this.onChangeSubsalesHOC}
            getStateLocation={this.getStateLocation}
            getSelectedTownShip={this.getSelectedTownShip}
            getSelectedSubArea={this.getSelectedSubArea}
            getSelectedSubsales={this.getSelectedSubsales}
            getSubsalesCurrentUser={this.getSubsalesCurrentUser}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    getBranches,
    getInvoicingBranches,
    getCountryDictionary,
    getAgencies,
    storeLastView,
    refreshToken,
  })(SubsalesWrappedComponent);
};

export default SubsalesHOC;
