import AtlasButton from "components/Button";
import CustomFormInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";

const Create = ({
  newEmailCampaign,
  onLoadEmailCampaigns,
  onChangeEmailCampaignsHOC,
  createEmailCampaign,
}) => {
  const handleClose = () => {
    onChangeEmailCampaignsHOC("showCreateModal", false);
    onChangeEmailCampaignsHOC("newEmailCampaign", {});
  };

  const handleChange = (value) => {
    onChangeEmailCampaignsHOC("newEmailCampaign", {
      ...newEmailCampaign,
      name: value,
    });
  };

  return (
    <ModalDialog
      title={"Create Email Campaign"}
      onLoad={onLoadEmailCampaigns}
      onClose={handleClose}
      footer={
        <div className={"d-flex g-2"}>
          <AtlasButton
            type={"button"}
            disabled={!newEmailCampaign.name}
            className={"btn-new btn-new--primary"}
            onClick={() => createEmailCampaign(newEmailCampaign)}
          >
            Create
          </AtlasButton>

          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--outline-secondary"}
            onClick={handleClose}
          >
            Cancel
          </AtlasButton>
        </div>
      }
    >
      <label htmlFor={"emails"} className={"at-form-input__title"} required>
        Campaign Name
      </label>
      <CustomFormInput
        type={"text"}
        value={newEmailCampaign.name || ""}
        onChangeValue={handleChange}
        maxLength={128}
        required={true}
      />
      <small className={"position-relative form-text text-muted"}>
        {newEmailCampaign.name ? newEmailCampaign.name.length : 0}/128
      </small>
    </ModalDialog>
  );
};

export default Create;
