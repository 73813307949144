// Previously was placed in share-components/atlas-forms/atlas-form-input in vuejs app
import React, { Component } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "assets/translation";

import "stylesheets/components/form-input/index.scss";
import "./index.scss";

class FormInput extends Component {
  state = {
    isFocused: false,
  };
  componentDidMount = () => {
    if (this.props.autoFocus && this.inputRef?.current) {
      this.inputRef.current.focus();
    }
  };

  onChangeValue = (event) => {
    if (this.props.maxLength) {
      if (event.target.value?.length <= this.props.maxLength) {
        this.props.onChangeValue(event.target.value);
      }
    } else {
      this.props.onChangeValue(event.target.value);
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (this.props.checkedPressEnterToSend) {
        this.props.onEnterToSend(this.props.value);
      }
    }
  };

  renderSuccessMessage = (message) => {
    if (message) {
      return (
        <div className="at-form-input__label at-form-input__label--success">
          {message}
        </div>
      );
    }
  };

  renderErrorMessage = (error) => {
    if (error) {
      return (
        <div
          className={
            this.props.errorMessagePlain
              ? `invalid-feedback`
              : `at-form-input__label at-form-input__label--error`
          }
        >
          {error}
        </div>
      );
    }
  };

  renderInstruction = (message) => {
    if (message) {
      return (
        <div
          className="at-form-input__label at-form-input__label--success"
          style={{ background: "#c2c2c2", left: 0, right: "auto" }}
        >
          {message}
        </div>
      );
    }
  };
  renderHelpText = (message) => {
    if (message) {
      return <div className="at-form-input__help_text">{message}</div>;
    }
  };
  renderSearchIcon = (type) => {
    if (type === "search") {
      return (
        <AtlasIcon
          className={"at-form-input__search-icon"}
          svgHref={"atlas-search-normal"}
        />
      );
    }
  };

  renderCloseButton = (showClearButton) => {
    if (showClearButton) {
      return (
        <AtlasIcon
          className={"at-form-input__close-icon"}
          svgHref={"atlas-X"}
          onClick={() => this.props.onChangeValue("")}
        />
      );
    }
  };

  renderNewClearButton = (showClearButton) => {
    if (showClearButton) {
      return (
        <button
          className={"btn-new btn-new--outline-secondary"}
          style={{
            minWidth: 60,
            width: 60,
          }}
          children={"Clear"}
          onClick={() => this.props.onChangeValue("")}
        />
      );
    }
  };

  renderRequired = (param, language) => {
    if (param) {
      return <div className="at-form-input__required">{getTranslation('required', language)}</div>;
    }
  };

  renderPrefix = (param) => {
    if (param) {
      return <span style={{ fontSize: 14 }}>{param}</span>;
    }
  };

  renderFooter = () => {
    const {
      required,
      footerClass,
      inputSuccess,
      inputError,
      instruction,
      helpText,
      language,
      additionalContent,
      showClearButton,
      clearButtonMode,
    } = this.props;

    if (
      required ||
      inputSuccess ||
      inputError ||
      instruction ||
      helpText ||
      additionalContent
    ) {
      return (
        <div
          className={`at-input_footer ${footerClass || ""}`}
          style={{ 
            marginBottom: "1rem",
            ...(showClearButton && clearButtonMode === "New") 
            ? {
              top: 41,
              right: 0,
              position: "absolute"
            }
            : {}
          }}>
          {this.renderRequired(required, language)}
          {this.renderSuccessMessage(inputSuccess)}
          {this.renderErrorMessage(inputError)}
          {this.renderInstruction(instruction)}
          {this.renderHelpText(helpText)}
          {additionalContent && additionalContent}
        </div>
      );
    }
    return "";
  };

  render() {
    const {
      style,
      autoFocus,
      calendarProps,
      type,
      required,
      inputRef,
      value,
      prefix,
      inputError,
      disabled,
      placeholder,
      className,
      containerClass,
      inputStyle,
      containerStyle,
      numericOnly,
      maxLength,
      onChangeFocus,
      onChangeBlur,
      showClearButton,
      clearButtonMode,
    } = this.props;

    return (
      <div
        className={`${containerClass || ""}`}
        style={{
          ...containerStyle,
          ...(showClearButton && clearButtonMode === "New"
            ? {
                display: "flex",
                flexWrap: "wrap",
                gridGap: 12,
                position: "relative",
              }
            : {}),
        }}
      >
        <div
          style={{
            ...style,
            ...(showClearButton && clearButtonMode === "New"
              ? {
                  minWidth: "calc(100% - 60px - 12px)",
                  width: "calc(100% - 60px - 12px)",
                }
              : {}),
          }}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
          className={`
            at-form-input
            ${type || ""}
            ${className || ""}
            ${this.state.isFocused ? "input-focused" : ""}
            ${inputError ? "at-form-input--error" : ""}
            ${disabled ? "at-form-input__disabled" : ""}
          `}
        >
          {this.renderPrefix(prefix)}
          {type !== "textarea" && (
            <input
              {...calendarProps}
              autoFocus={autoFocus}
              type={type}
              ref={inputRef}
              {...(numericOnly && {
                inputmode: "decimal",
                pattern: "[0-9]*",
              })}
              style={{ ...inputStyle }}
              onKeyDown={this.onKeyPress}
              className={"at-form-input__input"}
              value={value ?? ""}
              onChange={(event) => this.onChangeValue(event)}
              placeholder={placeholder}
              required={required}
              maxLength={maxLength}
              disabled={disabled}
              onWheel={(e) => e.target.blur()}
              onBlur={() => onChangeBlur && onChangeBlur()}
              onFocus={() => onChangeFocus && onChangeFocus()}
              step={0.01}
            />
          )}
          {type === "textarea" && (
            <textarea
              ref={inputRef}
              style={{ ...inputStyle }}
              className={`at-form-input__textarea`}
              value={value ?? 0}
              onKeyDown={this.onKeyPress}
              onChange={(event) => this.onChangeValue(event)}
              placeholder={placeholder}
              required={required}
              maxLength={maxLength}
              disabled={disabled}
              onBlur={() => onChangeBlur && onChangeBlur()}
              onFocus={() => onChangeFocus && onChangeFocus()}
            />
          )}
          {clearButtonMode !== "New" && this.renderCloseButton(showClearButton)}
          {this.renderSearchIcon(type)}
        </div>
        {clearButtonMode === "New" && this.renderNewClearButton(showClearButton)}
        {this.renderFooter()}
      </div>
    );
  }
}

export default FormInput;
