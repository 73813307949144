import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

import { getTranslation } from "assets/translation";
import "./index.scss";

const AtlasAutosuggest = ({
  value,
  updateValue,
  options,
  required,
  footerClass = "",
  disabled,
  language,
  containerClass = "",
  wrapperStyle,
  onSelect,
  inputStyle,
  placeholder,
  className,
}) => {
  const [suggestions, updateSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) => updateSuggestions(getSuggestions(value));

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    return options.filter((val) => {
      let tmpSuggestions = val.value.toLowerCase();

      return tmpSuggestions.indexOf(inputValue) > -1;
    });
  };

  const onSuggestionsClearRequested = () => updateSuggestions([]);

  const getSuggestionValue = (suggestion) => suggestion.value;

  const renderSuggestion = (suggestion) => <div>{suggestion.value}</div>;

  const renderRequired = () => <div className="at-autosuggest__required">{getTranslation('required', language)}</div>

  const renderFooter = () => {
    if (required) {
      return (
        <div
          className={`at-input_footer ${footerClass}`}
          style={ required ? { marginBottom: "1rem" } : {} }>
          {required && renderRequired()}
        </div>
      );
    }
    return "";
  };

  return (
    <div className={`${ containerClass }`}>
      <div
        className={`at-form-input${disabled ? " at-form-input__disabled" : " "} ${className || ""}`}
        style={wrapperStyle}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          shouldRenderSuggestions={() => true}
          onSuggestionSelected={(event, data) =>
            onSelect && onSelect(data.suggestion)
          }
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            style: { ...inputStyle },
            disabled,
            className: "at-form-input__input",
            placeholder: placeholder || "Type something...",
            value: value,
            onChange: (event, { newValue, method }) => updateValue(newValue),
          }}
        />
      </div>
      {renderFooter()}
    </div>
  );
};

export default AtlasAutosuggest;
