import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Delete } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      captcha: "",
      selectedDeleteSubsaleId: "",
      selectedDeleteSubsaleCode: "",
      loading: false,
    };

    componentDidUpdate = (pp) => {
      if (
        pp.showDeleteModal !== this.props.showDeleteModal &&
        !this.props.showDeleteModal
      ) {
        this.setState({ captcha: "" });
      }
    };

    load = (param) => this.setState({ loading: param });

    onChangeDeleteHOC = (val, context) => this.setState({ [context]: val });

    // delete listing
    onClickDeleteSubsale = (id, code) => {
      let temp = code.replace(/\s/g, "");
      Delete(
        `/sub_sales/${id}?confirmation=${temp}`,
        this.deleteSubsaleSuccess,
        this.deleteSubsaleError,
        this.load,
      );
    };
    deleteSubsaleSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.props.getSubsalesCurrentUser(currentPage, searchParams);
      this.props.onChangeSubsalesHOC(false, "showDeleteModal");
      this.props.onChangeSubsalesHOC(false, "showEditModal");
      this.props.onChangeSubsalesHOC(false, "showViewModal");

      requestSuccess(payload.message);
    };
    deleteSubsaleError = (error) => requestError(error && error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            captcha={this.state.captcha}
            onLoadDeleteSubsale={this.state.loading}
            selectedDeleteSubsaleId={this.state.selectedDeleteSubsaleId}
            selectedDeleteSubsaleCode={this.state.selectedDeleteSubsaleCode}
            onChangeDeleteHOC={this.onChangeDeleteHOC}
            onClickDeleteSubsale={this.onClickDeleteSubsale}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
