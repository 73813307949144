import React, { useEffect, useState } from 'react'
import Moment from 'moment';
import _ from 'lodash'
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";
import { BsTrash3Fill } from 'react-icons/bs';

import AlertBox from "components/AlertBox";
import AtlasSelect from "components/Select/new";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomToggle from "components/Button/toggle";
import ModuleFormHeader from "components/Form/header";
import CustomFormInput from "components/Input/formInput";
import PreviewModal from "containers/Dashboard/IQIDrive/Preview";
import DocumentPreview from "components/Modal/DocumentPreview";

import { replaceJSX } from 'utils/replaceJSX';
import TenancyAgreementHOC from "../actions/agreement";

const tenancyArray = [
  { label: "West Malaysia - Commercial", value: 1 },
  { label: "West Malaysia - Residential", value: 2 },
  { label: "Sabah - Commercial", value: 3 },
  { label: "Sabah - Residential", value: 4 },
  { label: "Sarawak - Commercial", value: 5 },
  { label: "Sarawak - Residential", value: 6 },
];

const TenancyAgreement = ({
  data,
  banks,
  partyData,
  selectedFile,
  specialRemarks,
  showPreviewModal,
  selected_form_property,
  onLoadTenancyAgreement,
  eProcessInitialPartyData,
  selected_confirmation_form,
  showDownloadPreviewModal,
  downloadedDocFile,

  onClose,
  getStampingFeeDoc,
  getSpecialRemarks,
  onChangeAgreementHOC,
  changeTabToPaticipant,
  updateTenancyAgreement,
  downloadTenancyAgreement,
  previewTenancyAgreement
}) => {
  const landlords = _.find(partyData, {id: 3})
  const tenant = _.find(eProcessInitialPartyData, {party_id: 1})
  const { sideMenuExpand } = data.sideMenuReducer;
  const { category_id, state_id, country_id } = selected_confirmation_form?.property_location ?? {}
  const [formData, setFormData] = useState({
    tenancy_type: "West Malaysia - Commercial",
    agreement_date: "",
    commencement_date: "",
    intended_tenure_years: "",
    renewal_tenure_years: "",
    address: "",
    land_area: "",
    monthly_rental_amount: "",
    bank_account_holder_name: "",
    bank_id: null,
    bank_account_number: "",
    general_deposit_amount: "",
    utility_deposit_amount: "",
    purpose: "",
    special_remarks: [],
    rent_free_from: "",
    rent_free_to: "",
    rent_free_months: "",
    customized_special_remarks: [],

    // Extra Deposits
    enable_extra_deposit: false,
    security_deposit_amount: "",
    access_card_deposit_amount: "",
    car_park_deposit_amount: "",
    restoration_deposit_amount: "",
  });

  useEffect(() => {
    getSpecialRemarks()
  }, [])

  useEffect(() => {
    const stateList = _.find(data.dictionaryReducer.countries, {id: country_id})?.states
    const state = _.find(stateList, { id: state_id })
    let temp = _.cloneDeep(formData)
    if ( category_id === 1) {
      if ( state?.name === 'Sabah' ){
        temp.tenancy_type = 4
      } else if ( state?.name === 'Sarawak' ) {
        temp.tenancy_type = 6
      } else {
        temp.tenancy_type = 2
      }
    } else {
      if ( state?.name === 'Sabah' ){
        temp.tenancy_type = 3
      } else if ( state?.name === 'Sarawak' ) {
        temp.tenancy_type = 5
      } else {
        temp.tenancy_type = 1
      }
    }

    Object.keys(formData).map(key => {
      if (key !== 'tenancy_type') {
        temp[key] = selected_confirmation_form[key]
      }
    })
    temp.land_area = selected_confirmation_form.property_location?.land_area
    temp.rent_free_from = selected_confirmation_form.rent_free_from ? Moment(selected_confirmation_form.rent_free_from).format("DD MMM YYYY") : ""
    temp.rent_free_to = selected_confirmation_form.rent_free_to ? Moment(selected_confirmation_form.rent_free_to).format("DD MMM YYYY") : ""
    temp.address = selected_confirmation_form.property_address
    temp.enable_extra_deposit = selected_confirmation_form.extra_deposits?.some(item => item.amount > 0)
    temp.security_deposit_amount = selected_confirmation_form.extra_deposits?.find(item => item.field === "security_deposit_amount")?.amount
    temp.access_card_deposit_amount = selected_confirmation_form.extra_deposits?.find(item => item.field === "access_card_deposit_amount")?.amount
    temp.car_park_deposit_amount = selected_confirmation_form.extra_deposits?.find(item => item.field === "car_park_deposit_amount")?.amount
    temp.restoration_deposit_amount = selected_confirmation_form.extra_deposits?.find(item => item.field === "restoration_deposit_amount")?.amount
    setFormData(temp)
  }, [selected_confirmation_form])

  const renderSpecialRemark = remark => {
    const variableComponent = {}
    remark.variable_list.map(variable => {
      variableComponent[variable?.value] = (
        <>
          {variable.type === "date" && (
            <div className="at-form-input" style={{ height: 24 }}>
              <AtlasIcon className={"at-form-input__calendar"} svgHref={"atlas-calendar-2-linear"} />
              <DatePicker
                dateFormat="DD MMM YYYY"
                className="at-form-input__input"
                value={formData[variable?.value]}
                onChange={(val) => setFormData({ ...formData, [variable?.value]: Moment(val).format("DD MMM YYYY") })}
              />
            </div>
          )}
          {variable.type !== "date" && (
            <CustomFormInput
              type={'number'}
              style={{ height: 24, width: 100 }}
              value={formData[variable?.value]}
              onChangeValue={(val) => setFormData({ ...formData, [variable?.value]: val })}
            />
          )}
        </>
      )
    })

    return (
      <CustomCheckbox
        outerContent={
          <div className='at-confirmation_form-tenancy-checkbox'>
            {replaceJSX(remark.content, variableComponent)}
          </div>
        }
        checked={formData.special_remarks.includes(remark.id)}
        onChangeCheckboxValue={event => {
          let temp = _.cloneDeep(formData)
          if (event.target.checked && !temp.special_remarks?.includes(remark.id)) {
            temp.special_remarks?.push(remark.id)
          } else if (!event.target.checked && temp.special_remarks?.includes(remark.id)) {
            temp.special_remarks = temp.special_remarks.filter(item => item !== remark.id)
          }
          setFormData(temp)
        }}
      />
    )
  }

  return (
    <>
      <div className='d-flex flex-column g-3 mb-3'>
        <AlertBox
          title={"Usage of the Tenancy Agreement"}
          containerClassName="w-100"
          containerClass="mb-0"
          description={
            <>
              The Tenancy Agreement was prepared by our IQI Panel Lawyers. Please note that you are required to collect the Disbursement Fees as per the
              <span className="at-redirect__link" onClick={() => getStampingFeeDoc()}>TA Stamping Schedule Fee</span>.
            </>
          }
        />
        {!(category_id && state_id && country_id) && (
          <AlertBox
            mode={"danger"}
            disabledClose={true}
            description={`Property Category, State and Country are required to proceed with the Tenancy Agreement. Please update the Property tab with the required information before proceeding.`} />
        )}
        {[4, 5].includes(category_id) && (
          <AlertBox
            mode={"danger"}
            disabledClose={true}
            description={`Tenancy Agreement is not available for ${category_id === 4 ? 'Agricultural' : 'Unknown'}`} />
        )}
      </div>
      {(category_id && state_id && country_id && ![4, 5].includes(category_id)) && (
        <div className="at-form__content">
          <AlertBox
            mode={"info"}
            containerClassName="mb-3"
            description={`Before downloading the Tenancy Agreement, please double-check that you entered all of the required information correctly below since the downloaded document will be auto-filled with the details provided.`}
          />
          <div className='at-form__content'>
            <ModuleFormHeader title={"Tenancy Details"} moduleIcon={"atlas-calendar"} className={'mb-3'} />
            <div className='grid-control'>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Tenancy Agreement Type</h2>
                <p>{_.find(tenancyArray, { value: formData.tenancy_type})?.label ?? "-" }</p>
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Date of Agreement</h2>
                <div className="at-form-input">
                  <AtlasIcon
                    className={"at-form-input__calendar"}
                    svgHref={"atlas-calendar-2-linear"} />
                  <AtlasIcon
                    style={{ marginRight: 25, width: 16, height: 16, fill: '#374151' }}
                    className={"at-form-input__close-icon"}
                    svgHref={"atlas-X"}
                    onClick={() => setFormData({ ...formData, agreement_date: ""})} />
                  <DatePicker
                    dateFormat="DD MMM YYYY"
                    className="at-form-input__input"
                    value={formData.agreement_date}
                    onChange={(val) => setFormData({ ...formData, agreement_date: Moment(val).format("DD MMM YYYY") })}
                  />
                </div>
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Date of Commencement</h2>
                <div className="at-form-input">
                  <AtlasIcon className={"at-form-input__calendar"} svgHref={"atlas-calendar-2-linear"} />
                  <DatePicker
                    dateFormat="DD MMM YYYY"
                    className="at-form-input__input"
                    value={formData.commencement_date}
                    onChange={(val) => setFormData({ ...formData, commencement_date: Moment(val).format("DD MMM YYYY") })}
                  />
                </div>
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Term (Years)</h2>
                <CustomFormInput
                  type={"number"}
                  value={formData.intended_tenure_years}
                  onChangeValue={(val) => setFormData({ ...formData, intended_tenure_years: val })}
                  placeholder={'Term (Years)'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Option To Renew (Years)</h2>
                <CustomFormInput
                  type={"number"}
                  value={formData.renewal_tenure_years}
                  onChangeValue={(val) => setFormData({ ...formData, renewal_tenure_years: val })}
                  placeholder={'Option To Renew (Years)'}
                />
              </section>
            </div>
          </div>
          <div className='at-form__content g-3'>
            <ModuleFormHeader title={"Landlord Name and Particulars"} moduleIcon={"atlas-user"} className={'mb-3'} />
            <div className='at-tenancy-agreement_partcipants'>
              {landlords?.data?.length === 0 && (
                <button
                  className='btn-new btn-new--primary'
                  style={{ width: 'fit-content' }}
                  onClick={() => changeTabToPaticipant()} >
                  Assign Landlord
                </button>
              )}
              {landlords?.data?.map((item, index) => {
                if (index < 3) {
                  return (
                    <div className='grid-control'>
                      <section className='grid-third-col'>
                        <h2 className='at-form-input__title'>Name</h2>
                        <p>{item.signee_type_id === 0 ? item.full_name : item.company_name}</p>
                      </section>
                      <section className='grid-third-col'>
                      <h2 className='at-form-input__title'>{item.signee_type_id === 0 ? 'NRIC/Passport No.' : 'Company No.'}</h2>
                        <p>{item.signee_type_id === 0 ? item.identification_number : item.company_registration_no}</p>
                      </section>
                      <section className='grid-third-col'>
                        <h2 className='at-form-input__title'>Contact No.</h2>
                        <p>{item.mobile_contact_number || "-"}</p>
                      </section>
                      <section className='grid-third-col'>
                        <h2 className='at-form-input__title'>Email</h2>
                        <p>{item.email || "-"}</p>
                      </section>
                      <section className='grid-third-col'>
                        <h2 className='at-form-input__title'>Address</h2>
                        <p>{item.full_address || ''}</p>
                      </section>
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader title={"Tenant Name and Particulars"} moduleIcon={"atlas-user"} className={'mb-3'} />
            <div className='at-tenancy-agreement_partcipants'>
              {_.isEmpty(tenant) && (
                <button
                  className='btn-new btn-new--primary'
                  style={{ width: 'fit-content' }}
                  onClick={() => changeTabToPaticipant()} >
                  Assign Tenant
                </button>
              )}
              {!_.isEmpty(tenant) && (
                <div className='grid-control'>
                  <section className='grid-third-col'>
                    <h2 className='at-form-input__title'>Name</h2>
                    <p>{tenant?.signee_type_id === 0 ? tenant?.full_name : (tenant?.company_name ?? '-')}</p>
                  </section>
                  <section className='grid-third-col'>
                  <h2 className='at-form-input__title'>{tenant?.signee_type_id === 0 ? 'NRIC/Passport No.' : 'Company No.'}</h2>
                    <p>{tenant?.signee_type_id === 0 ? tenant?.identification_number : (tenant?.company_registration_no ?? '-')}</p>
                  </section>
                  <section className='grid-third-col'>
                    <h2 className='at-form-input__title'>Contact No.</h2>
                    <p>{tenant?.mobile_contact_number || '-'}</p>
                  </section>
                  <section className='grid-third-col'>
                    <h2 className='at-form-input__title'>Email</h2>
                    <p>{tenant?.email || '-'}</p>
                  </section>
                  <section className='grid-third-col'>
                    <h2 className='at-form-input__title'>Address</h2>
                    <p>{tenant?.full_address || ''}</p>
                  </section>
                </div>
              )}
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader title={"Demised Premises"} moduleIcon={"atlas-location"} className={'mb-3'} />
            <div className='grid-control'>
              <section className='grid-full-col'>
                <h2 className='at-form-input__title'>Address</h2>
                <CustomFormInput
                  type={"text"}
                  value={formData.address}
                  disabled={true}
                  onChangeValue={(val) => setFormData({ ...formData, address: val })}
                  placeholder={'Address'}
                />
              </section>
              {[43, 50, 51, 29].includes(selected_form_property?.property_type_id) && (
                <section className='grid-third-col'>
                  <h2 className='at-form-input__title'>Land Area (Square Meters)</h2>
                  <CustomFormInput
                    type={"text"}
                    value={formData.land_area}
                    onChangeValue={(val) => setFormData({ ...formData, land_area: val })}
                    placeholder={'Land Area (Square Meters)'}
                  />
                </section>
              )}
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader title={"Monthly Rental"} moduleIcon={"atlas-money"} className={'mb-3'} />
            <div className='grid-control'>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Rental Fee</h2>
                <CustomFormInput
                  type={"text"}
                  value={formData.monthly_rental_amount}
                  onChangeValue={(val) => setFormData({ ...formData, monthly_rental_amount: val })}
                  placeholder={'Rental Fee'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Account Holder’s Name</h2>
                <CustomFormInput
                  type={"text"}
                  value={formData.bank_account_holder_name}
                  onChangeValue={(val) => setFormData({ ...formData, bank_account_holder_name: val })}
                  placeholder={'Account Holder’s Name'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Bank Name</h2>
                <AtlasSelect
                  value={formData.bank_id || ""}
                  disabledPlaceholder={true}
                  onChange={(e) => setFormData({ ...formData, bank_id: e.target.value})}>
                  {banks?.map((item) => (
                    <MenuItem
                      classes={{ root: `at-select__dropdown-item` }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </AtlasSelect>
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Account No.</h2>
                <CustomFormInput
                  type={"text"}
                  value={formData.bank_account_number}
                  onChangeValue={(val) => setFormData({ ...formData, bank_account_number: val })}
                  placeholder={'Account No.'}
                />
              </section>
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader title={"Deposits"} moduleIcon={"atlas-money-2"} className={'mb-3'} />
            <p className='fs-2 mb-3'>Use General Deposit for an all-inclusive sum, or switch to Optional Deposits to specify individual amounts for each deposits</p>
            <div className='grid-control mb-3'>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>General Deposits (RM)</h2>
                <CustomFormInput
                  type={"number"}
                  value={formData.general_deposit_amount}
                  disabled={formData.enable_extra_deposit}
                  onChangeValue={(val) => setFormData({ ...formData, general_deposit_amount: val })}
                  placeholder={'General Deposits (RM)'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className='at-form-input__title'>Utilities Deposits (RM)</h2>
                <CustomFormInput
                  type={"number"}
                  value={formData.utility_deposit_amount}
                  onChangeValue={(val) => setFormData({ ...formData, utility_deposit_amount: val })}
                  placeholder={'Utilities Deposits (RM)'}
                />
              </section>
            </div>
            <ModuleFormHeader title={"Optional Deposits (Included in General Deposit)"} moduleIcon={"atlas-money-2"} className={'mb-3'} />
            <div className='d-flex align-items-basline mt-2'>
              <CustomToggle
                className={"at-toggle__secondary"}
                currentState={!formData.enable_extra_deposit}
                onToggleButton={() => setFormData({ ...formData, enable_extra_deposit: !formData.enable_extra_deposit })}
              />
              <h2 className="at-form-input__title mb-3">Use Optional Deposits</h2>
            </div>
            <div className='grid-control'>
              <section className='grid-third-col'>
                <h2 className="at-form-input__title">Security Deposit (RM)</h2>
                <CustomFormInput
                  type={"number"}
                  disabled={!formData.enable_extra_deposit}
                  value={formData.security_deposit_amount}
                  onChangeValue={(val) => setFormData({ ...formData, security_deposit_amount: parseFloat(val) })}
                  placeholder={'Security Deposit (RM)'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className="at-form-input__title">Access Card Deposit (RM)</h2>
                <CustomFormInput
                  type={"number"}
                  disabled={!formData.enable_extra_deposit}
                  value={formData.access_card_deposit_amount}
                  onChangeValue={(val) => setFormData({ ...formData, access_card_deposit_amount: parseFloat(val) })}
                  placeholder={'Access Card Deposit (RM)'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className="at-form-input__title">Car Park Deposit (RM)</h2>
                <CustomFormInput
                  type={"number"}
                  disabled={!formData.enable_extra_deposit}
                  value={formData.car_park_deposit_amount}
                  onChangeValue={(val) => setFormData({ ...formData, car_park_deposit_amount: parseFloat(val) })}
                  placeholder={'Car Park Deposit (RM)'}
                />
              </section>
              <section className='grid-third-col'>
                <h2 className="at-form-input__title">Restoration Deposit (RM)</h2>
                <CustomFormInput
                  type={"number"}
                  disabled={!formData.enable_extra_deposit}
                  value={formData.restoration_deposit_amount}
                  onChangeValue={(val) => setFormData({ ...formData, restoration_deposit_amount: parseFloat(val) })}
                  placeholder={'Restoration Deposit (RM)'}
                />
              </section>
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader title={"More Info"} moduleIcon={"atlas-document-text"} className={'mb-3'} />
            <div className='grid-control'>
              <section className='grid-full-col'>
                <h2 className='at-form-input__title'>Purpose</h2>
                <CustomFormInput
                  type={"text"}
                  value={formData.purpose}
                  onChangeValue={(val) => setFormData({ ...formData, purpose: val })}
                  placeholder={'Residential Only'}
                />
              </section>
              <section className='grid-full-col'>
                {specialRemarks?.map(remark => renderSpecialRemark(remark))}
              </section>
              <section className='grid-full-col'>
                {formData.customized_special_remarks?.map((remark, index) => (
                  <>
                    {index === 0 && <h2 className='at-form-input__title'>Special Remarks</h2>}
                    <div className='d-flex align-items-center g-3 mb-2'>
                      <CustomFormInput
                        type={"text"}
                        value={remark}
                        containerClass={'w-100'}
                        onChangeValue={(val) => {
                          let temp = _.cloneDeep(formData)
                          temp.customized_special_remarks[index] = val
                          setFormData(temp)
                        }}
                      />
                      <button
                       className='at-table-icon-button'
                       onClick={() => {
                          let temp = _.cloneDeep(formData)
                          temp.customized_special_remarks.splice(index, 1)
                          setFormData(temp)
                       }}>
                        <BsTrash3Fill style={{ width: 18, height: 18, color: "#F04438" }} />
                      </button>
                    </div>
                  </>
                ))}
                <button
                  className='btn-new btn-new--primary'
                  onClick={() => {
                    let temp = _.cloneDeep(formData)
                    temp.customized_special_remarks.push("")
                    setFormData(temp)
                  }}>
                  <AtlasIcon svgHref={"atlas-add-linear"} style={{ width: 18, height: 18, fill: "white" }} />
                  New Special Remark
                </button>
              </section>
            </div>
          </div>
          <div className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}>
            <button
              className="btn-new btn-new--success"
              onClick={() => updateTenancyAgreement(selected_confirmation_form.id, {
                ...formData,
                customized_special_remarks: formData.customized_special_remarks?.filter(item => item !== ""),
                extra_deposits: [
                  ...((formData.security_deposit_amount && formData.enable_extra_deposit) ? [{ field: "security_deposit_amount", amount: formData.security_deposit_amount }] : []),
                  ...((formData.access_card_deposit_amount && formData.enable_extra_deposit) ? [{ field: "access_card_deposit_amount", amount: formData.access_card_deposit_amount }] : []),
                  ...((formData.car_park_deposit_amount && formData.enable_extra_deposit) ? [{ field: "car_park_deposit_amount", amount: formData.car_park_deposit_amount }] : []),
                  ...((formData.restoration_deposit_amount && formData.enable_extra_deposit) ? [{ field: "restoration_deposit_amount", amount: formData.restoration_deposit_amount }] : []),
                ]
              })} >
              Save
            </button>
            <button
              className="btn-new btn-new--secondary"
              onClick={() => previewTenancyAgreement(selected_confirmation_form.id, selected_confirmation_form.transaction_number)} >
              Preview
            </button>
            <button
              className="btn-new btn-new--secondary"
              onClick={() => downloadTenancyAgreement(selected_confirmation_form.id, selected_confirmation_form.transaction_number)} >
              Download
            </button>
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClose()} >
              Close
            </button>
          </div>
        </div>
      )}
      {showDownloadPreviewModal && (
        <DocumentPreview
          selectedFile={downloadedDocFile}
          showPreviewModal={showDownloadPreviewModal}
          onClosePreviewModal={() => onChangeAgreementHOC('showDownloadPreviewModal', false)} />
      )}
      {showPreviewModal && (
        <PreviewModal
          selectedFile={selectedFile}
          showPreviewModal={showPreviewModal}
          onClosePreviewModal={() => onChangeAgreementHOC('showPreviewModal', false)} />
      )}
      {onLoadTenancyAgreement && <LoadingModal />}
    </>
  )
}

export default TenancyAgreementHOC(TenancyAgreement);