import { useState } from "react";

import AtlasButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import Form from "./components/Form";

import isEmptyValue from "utils/isEmpty";

const Create = ({
  categories,
  onLoadEmailTemplates,
  onChangeEmailTemplatesHOC,
  createEmailTemplate,
}) => {
  const [values, setValues] = useState({
    name: "",
    subject: "",
    categories: [],
    attachment: null,
    status_id: 1,
    html_code: "<!DOCTYPE html><html></html>",
    content: {},
  });

  const handleChange = (key, val) => {
    setValues((values) => ({ ...values, [key]: val }));
  };

  const handleCreate = () => {
    const {
      attachment,
      categories,
      content,
      html_code,
      name,
      status_id,
      subject,
    } = values;

    const formData = new FormData();

    formData.append("name", name.trim());
    formData.append("type_id", 1);
    formData.append("status_id", parseInt(status_id));
    formData.append("html_code", html_code);
    formData.append("content[body]", JSON.stringify(content));
    formData.append("extra[subject]", subject.trim());
    formData.append("new_attachment", true);
    
    if (!isEmptyValue(attachment)) {
      formData.append("attachment", attachment);
    }

    if (categories.length > 0) {
      for (const category of categories) {
        formData.append(
          "sort_list[]",
          category && category.customOption ? category.label : category,
        );
      }
    }

    createEmailTemplate(formData);
  };

  return (
    <ModalDialog
      title={"Create Email Template"}
      onLoad={onLoadEmailTemplates}
      onClose={() => onChangeEmailTemplatesHOC("showCreateModal", false)}
      maxWidth={"sm"}
      responsiveSize={"sm"}
      children={
        <div className={"at-email-templates__form__card"}>
          <Form
            values={values}
            categories={categories}
            attachment={values.attachment}
            context={"create"}
            handleChange={handleChange}
          />
        </div>
      }
      footer={
        <div className={"d-flex g-3"}>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--primary m-0"}
            onClick={handleCreate}
            disabled={values.name === "" || values.subject === ""}
            children={"Create"}
          />
          <AtlasButton
            type={"button"}
            onClick={() => onChangeEmailTemplatesHOC("showCreateModal", false)}
            className={"btn-new btn-new--outline-secondary m-0"}
            children={"Cancel"}
          />
        </div>
      }
    />
  );
};

export default Create;
