import React, { useEffect } from 'react';
import Moment from 'moment';
import _ from 'lodash';

const RenderSearchReset = ({
  showResetButton,
  searchParams,
  onChangeSearchParams,
  getListAPI,
  mode,
  onToggleSearchModal,
  searchLabel,
  handleSearch
}) => {

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleSearch();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [searchParams]);

  return (
    <div className="d-flex">
      {showResetButton && (
        <button
          className="btn-new btn-new--outline-secondary mr-10"
          onClick={() => {
            let tmp = _.cloneDeep(searchParams);
            tmp.map((item) => {
              if (item.type === "select-multi") {
                item.param = [];
              } else {
                item.param = "";
              }
            });
            onChangeSearchParams(tmp);
            getListAPI(1, "");

            if (mode === "mobile") {
              onToggleSearchModal(false);
            }
          }}
        >
          Reset
        </button>
      )}
      <button
        className="btn-new btn-new--secondary"
        onClick={handleSearch}
      >
        {searchLabel || "Search"}
      </button>
    </div>
  );
};

export default RenderSearchReset;