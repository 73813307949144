import { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationDialog from "components/Modal/delete";
import ModuleHeader from "components/ModuleHeader";
import CreateEmailTemplate from "./Create";
import UpdateEmailTemplate from "./Update";
import AtPaginatedTable from "./components/Table";

import withEmailTemplates from "./actions";
import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";
import _ from "lodash";

const EmailTemplates = ({
  data,
  showCreateModal,
  showUpdateModal,
  showDeleteModal,
  emailTemplates,
  emailTemplatesPages,
  categories,
  onLoadEmailTemplates,
  selectedEmailTemplate,
  searchParams,
  selectedId,
  
  getCategories,
  getEmailTemplates,
  updateEmailTemplate,
  createEmailTemplate,
  deleteEmailTemplate,
  getSelectedEmailTemplate,
  onChangeEmailTemplatesHOC,
}) => {
  const { can_create, can_update, can_destroy } = permissionsChecker("Email Templates", data,);

  useEffect(() => {
    getCategories();
    getEmailTemplates();
  }, []);

  const renderCreateDialog = () => (
    <CreateEmailTemplate
      categories={categories}
      onLoadEmailTemplates={onLoadEmailTemplates}

      createEmailTemplate={createEmailTemplate}
      onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
    />
  );

  const renderUpdateDialog = () => (
    <UpdateEmailTemplate
      categories={categories}
      onLoadEmailTemplates={onLoadEmailTemplates}
      selectedEmailTemplate={selectedEmailTemplate}

      onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
      updateEmailTemplate={updateEmailTemplate}
    />
  );

  const renderDeleteDialog = () => (
    <DeleteConfirmationDialog
      open={showDeleteModal}
      title={"Delete email template"}
      message={"Are you sure you want to delete this template?"}
      negativeAction={() => onChangeEmailTemplatesHOC("showDeleteModal", false)}
      positiveText={"Yes"}
      positiveAction={() => deleteEmailTemplate(selectedId)}
    />
  );

  return (
    <>
      <ModuleHeader
        atlasIcon={"atlas-sms-tracking"}
        title={"Email Templates"}
        actionButton={[
          {
            label: "New Email Template",
            onShow: can_create,
            className: "btn-new btn-new--primary",
            icon: <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />,
            onClick: () => onChangeEmailTemplatesHOC("showCreateModal", true)
          },
        ]}
      />
      <CustomCard
        cardContent={
          <AtPaginatedTable
            rowData={emailTemplates.data}
            meta={emailTemplates.meta}
            searchParams={searchParams}
            totalPages={emailTemplatesPages}
            can_update={can_update}
            can_destroy={can_destroy}
            getListAPI={getEmailTemplates}
            onClickEdit={(id) => getSelectedEmailTemplate(id)}
            onClickDelete={(id) => {
              onChangeEmailTemplatesHOC("selectedId", id);
              onChangeEmailTemplatesHOC("showDeleteModal", true);
            }}
            onChangeSearchParams={(val) =>
              onChangeEmailTemplatesHOC("searchParams", val)
            }
          />
        }
      />
      {onLoadEmailTemplates && <LoadingModal />}
      {showCreateModal && renderCreateDialog()}
      {showUpdateModal && renderUpdateDialog()}
      {renderDeleteDialog()}
    </>
  );
};

export default withEmailTemplates(EmailTemplates);
