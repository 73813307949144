import React, { useState, useMemo, useCallback } from "react";
import _ from "lodash";
import { compose } from "redux";
import { MenuItem } from "@material-ui/core";

import CustomCard from "components/Card";
import SvgIcon from "components/Icon/new";
import CustomTabs from "components/Tab/new";
import FormChart from "components/Chart/form";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCloseButton from "components/Button/prev";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import CustomDropdownButton from "components/Button/dropdown";
import SectionExternalAgent from "../containers/ClaimForm/ClaimFormSections/externalAgent";
import SubsalesSimilarProperties from "../containers/SimilarProperties";
import SubsalesParticipants from "../containers/Participants";
import SubsalesSubmissions from "../containers/Submission";
import SubsalesActivityLogs from "../containers/Activity";
import SubsalesClaimForm from "../containers/ClaimForm";
import SubsalesDocuments from "../containers/Document";
import SubsalesMessages from "../containers/Message";
import DocsFinance from "../containers/DocsFinance";
import UnitInfo from "../containers/UnitInfo";
import ConfirmationModal from "components/Modal/confirmation";

import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import isEmptyValue from "utils/isEmpty";
import {
  subsaleRepresentativeBadge,
  subrentRepresentativeBadge,
} from "../assets";
import ApptLetterHOC from "../actions/apptLetter";
import ContactHOC from "../actions/contact";

const sections = [
  { label: "Claim Form", icon: <AtlasIcon svgHref="atlas-building-3" /> },
  {
    label: "Participants",
    icon: <SvgIcon svgHref="confirmation-form_participants" />,
  },
  {
    label: "Documents",
    icon: <SvgIcon svgHref="confirmation-form_document-text" />,
  },
  { label: "Submit Claims", icon: <AtlasIcon svgHref="atlas-receipt-1" /> },
  { label: "Similar Properties", icon: <AtlasIcon svgHref="atlas-building" /> },
  {
    label: "Docs from Finance",
    icon: <AtlasIcon svgHref="svg-iconsax-receipt" />,
  },
  { label: "Messages", icon: <AtlasIcon svgHref="svg-iconsax-message-text" /> },
  { label: "Activity", icon: <AtlasIcon svgHref="svg-iconsax-chart" /> },
  { label: "Unit Info", icon: <AtlasIcon svgHref="atlas-house-2" /> },
];
const UpdateClaimForm = ({
  agencies,
  data,
  contacts,
  history,
  banks,
  showExternalAgent,
  enableValidateBank,
  selectedAttachedApptLetter,
  unattachedAppointmentLetters,
  onLoadSubsaleClaims,
  selectedSection,
  onSelectSection,
  selected_claim,
  claim_activities,
  approveSubsaleClaim,
  rejectSubsaleClaim,
  showTransferModal,
  townshipsList,
  showSelectForm,
  showAssignContactModal,
  showContactLoadingModal,
  getBanks,
  getSelectedTownShip,
  getPendingReassign,
  getActivity,
  transferConfirmation,
  onChangeUpdateSelectedClaim,
  getSubsaleClaimsPreview,
  getCurrentUserContactList,
  updateSelectedClaim,
  updateRepresentativeType,
  getSelectedClaim,
  getSubmittedSubsaleClaims,
  onChangeClaimsHOC,
  getUnattachedApptLetters,
  onChangeAppointmentLetterHOC,
  submitApptForm,
  detachApptForm
}) => {
  const [detachData, setDetachData] = useState({});

  const { role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const setShowExternalAgent = (val) => onChangeClaimsHOC("showExternalAgent", val);

  const {
    confirmation_form_number,
    id,
    representative_id,
    sub_sale_type_id,
  } = selected_claim;
  const tmpConfitmationFormNumber = `${!isEmptyValue(confirmation_form_number) ? `/ ${confirmation_form_number}` : ""}`;

  const onChangeSelect = (value) => {
    onSelectSection(value);
    if (value === "Claim Form") {
      getSelectedClaim(id);
    }
  };
  const representativeEntity = useMemo(() => {
    if (representative_id !== null) {
      const tmpRepresentative =
        sub_sale_type_id === 1
          ? _.find(subsaleRepresentativeBadge, { value: representative_id })
          : _.find(subrentRepresentativeBadge, { value: representative_id });
      return tmpRepresentative || {};
    }

    return {};
  }, [sub_sale_type_id, representative_id]);

  const { label: representativeLabel, badgeColor } = representativeEntity;

  const renderFormsChart = () => {
    const { forms_chart, invoicing_branch_id, type_id, id } = selected_claim;

    return (
      forms_chart !== null && (
        <FormChart
          containerClass="mb-3"
          history={history}
          infoData={{
            invoicing_branch_id: invoicing_branch_id,
            type_id: type_id
          }}
          currentFormType={"Claim Form"}
          forms_chart={forms_chart}
          onClickButton={(data) => {
            if ((data.type = "Appointment Letter")) {
              getUnattachedApptLetters(id);
            }
          }}
        />
      )
    );
  };

  const renderAttachFormModal = () => {
    const tmpSelectedAttachedForm = _.find(unattachedAppointmentLetters, {
      id: selectedAttachedApptLetter?.id,
    });

    if (showSelectForm) {
      return (
        <ModalDialog
          title={"Attach Appointment Letter"}
          fullHeight={false}
          onClose={() => onChangeAppointmentLetterHOC(false, "showSelectForm")}
          children={
            <div className="at-apt_letter-closing_form-cont">
              <p className="mb-3 fs-2">
                Please choose an option to attach appointment letter:
              </p>
              <div className="mt-3 mb-4" style={{ height: 250 }}>
                <h2 className="at-form-input__title mr-10">Form Number</h2>
                <CustomTypeahead
                  showClearButton
                  typeaheadId={"unclaimedAppointmentForm"}
                  options={unattachedAppointmentLetters || []}
                  selectedValue={
                    tmpSelectedAttachedForm ? [tmpSelectedAttachedForm] : []
                  }
                  labelKey={"transaction_number"}
                  filterBy={["transaction_number", "property_address"]}
                  onSelect={(val) => {
                    if (val && val.length > 0) {
                      return onChangeAppointmentLetterHOC(val[0], 'selectedAttachedApptLetter');
                    }
                    return onChangeAppointmentLetterHOC("", 'selectedAttachedApptLetter');
                  }}
                  childrenHead={(rowItem) => (
                    <p>{rowItem?.transaction_number}</p>
                  )}
                  highlighterData={[(option = {}) => option?.property_address,]}
                />
              </div>
              <div className="at-modal_dialog-container-footer d-flex grid_gap-1">
                <button
                  disabled={isEmptyValue(selectedAttachedApptLetter)}
                  className="btn-new btn-new--primary"
                  onClick={() => submitApptForm(selected_claim.id, selectedAttachedApptLetter.id)}>
                  Submit
                </button>
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() => onChangeAppointmentLetterHOC(false, "showSelectForm")}>
                  Cancel
                </button>
              </div>
            </div>
          }
        />
      );
    }
  };

  const renderChangeRepresentId = useMemo(() => {
    let tmpMenuList = []
    if(selected_claim.form_type_id === 2) { 
      if(selected_claim.representative_id !== 1) {
        tmpMenuList.push(
          {
            label: `Represent ${selected_claim.sub_sale_type_id === 1 ? "Vendor" : "Landlord" }`,
            value: 1
          }
        )
      }
      if(selected_claim.representative_id !== 2) {
        tmpMenuList.push(
          {
            label: `Represent ${selected_claim.sub_sale_type_id === 1 ? "Purchaser" : "Tenant" }`,
            value: 2
          }
        )
      }
      if(selected_claim.representative_id !== 3) {
        tmpMenuList.push(
          {
            label: `Represent Both`,
            value: 3
          }
        )
      }
    } else if([1, 3, 4].indexOf(selected_claim.form_type_id) > -1) {
      if(selected_claim.representative_id !== 3) {
        tmpMenuList.push(
          {
            label: `Represent Both`,
            value: 3
          }
        )
      }
    }
    return tmpMenuList
  }, [selected_claim])

  return (
    <CustomCard
      className={"p-sm-3 h-100"}
      containerStyle={{ background: "#F3F4F6" }}
      cardContent={
        <div style={{ height: "auto", minHeight: "80vh" }}>
          <div className="at-card__header">
            <AtlasCloseButton onClick={() => onChangeClaimsHOC("showUpdateClaimFormModal", false)}/>
            <h4 className="at-card__title p-0">{`Edit Claim Form ${tmpConfitmationFormNumber}`}</h4>
            {representative_id !== null && (
              <div
                className="at-status_badge text-center"
                style={{ ...getColorBadge(badgeColor) }}>
                {representativeLabel}
              </div>
            )}
            {
              (role === "Super Admin" && (renderChangeRepresentId && renderChangeRepresentId?.length > 0) && selected_claim.representative_id) && (
                <CustomDropdownButton 
                  wrapperStyle={{marginLeft: "-0.5rem"}}
                  iconRef={"atlas-arrow-swap_horizontal"}
                  iconStyle={{color: "#3B82F6", padding: 0}}
                  onSelect={ option => updateRepresentativeType(selected_claim.id, option.value) }
                  options={renderChangeRepresentId}
                />
              )
            }
          </div>
          {renderFormsChart()}
          <CustomTabs
            sections={sections}
            selectedSection={selectedSection}
            onSelectSection={onChangeSelect}/>
          <div>
            {selectedSection === "Claim Form" && (
              <SubsalesClaimForm
                agencies={agencies}
                banks={banks}
                selected_claim={selected_claim}
                onLoadSubsaleClaims={onLoadSubsaleClaims}
                onSelectSection={onChangeSelect}
                showTransferModal={showTransferModal}
                townshipsList={townshipsList}
                getSelectedTownShip={getSelectedTownShip}
                getSubsaleClaimsPreview={getSubsaleClaimsPreview}
                getSelectedClaim={getSelectedClaim}
                updateSelectedClaim={updateSelectedClaim}
                transferConfirmation={transferConfirmation}
                onClickNav={(val) => onChangeSelect(val)}
                
                onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                onChangeClaimsHOC={onChangeClaimsHOC}
                getBanks={getBanks}
                setShowExternalAgent={setShowExternalAgent}
                onCloseForm={() => onChangeClaimsHOC("showUpdateClaimFormModal", false)}
              />
            )}
            {selectedSection === "Participants" && (
              <SubsalesParticipants
                banks={banks}
                permissionsChecker={permissionsChecker("Subsales Claims", data)}
                selected_claim={selected_claim}
                showAssignContactModal={showAssignContactModal}
                contacts={contacts}
                showContactLoadingModal={showContactLoadingModal}

                menuExpand={data.sideMenuReducer.sideMenuExpand}
                onClickNav={(val) => onChangeSelect(val)}
                onCloseForm={() =>
                  onChangeClaimsHOC("showUpdateClaimFormModal", false)
                }
                getCurrentUserContactList={getCurrentUserContactList}
                onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                onChangeClaimsHOC={onChangeClaimsHOC}
                getSelectedClaim={getSelectedClaim}
                getBanks={getBanks}
                updateSelectedClaim={updateSelectedClaim}
              />
            )}
            {selectedSection === "Documents" && (
              <SubsalesDocuments
                selected_claim={selected_claim}
                approveSubsaleClaim={approveSubsaleClaim}
                rejectSubsaleClaim={rejectSubsaleClaim}
                getSelectedClaim={getSelectedClaim}
                onLoadSubsaleClaims={onLoadSubsaleClaims}
                onClickNav={(val) => onChangeSelect(val)}
                onCloseForm={() =>
                  onChangeClaimsHOC("showUpdateClaimFormModal", false)
                }
              />
            )}
            {selectedSection === "Submit Claims" && (
              <SubsalesSubmissions
                selected_claim={selected_claim}
                getPendingReassign={getPendingReassign}
                getSubmittedSubsaleClaims={getSubmittedSubsaleClaims}
                onClickNav={(val) => onChangeSelect(val)}
                onCloseForm={() =>
                  onChangeClaimsHOC("showUpdateClaimFormModal", false)
                }
              />
            )}
            {selectedSection === "Similar Properties" && (
              <SubsalesSimilarProperties selected_claim={selected_claim} />
            )}
            {selectedSection === "Messages" && (
              <SubsalesMessages selected_claim={selected_claim} />
            )}
            {selectedSection === "Activity" && (
              <SubsalesActivityLogs
                selected_claim={selected_claim}
                claim_activities={claim_activities}
                getActivity={getActivity}
              />
            )}
            {selectedSection === "Unit Info" && (
              <UnitInfo selected_claim={selected_claim} />
            )}
            {selectedSection === "Docs from Finance" && (
              <DocsFinance
                permissionChecker={permissionsChecker("Subsales Claims", data)}
                selected_claim={selected_claim}
              />
            )}
          </div>
          {renderAttachFormModal()}
          {showExternalAgent && (
            <ModalDialog
              title={"Add Co-Agency"}
              fullWidth={true}
              onClose={() => {
                getSelectedClaim(id)
                setShowExternalAgent(false)
              }}
              children={
                <SectionExternalAgent
                  data={data}
                  from={"Agency Fee"}
                  modalForm={true}
                  agencies={agencies}
                  enableValidateBank={enableValidateBank}
                  permissionsChecker={permissionsChecker("Subsales Claims", data)}
                  banks={banks}
                  selected_claim={selected_claim}
                  onToggleClose={(disableRefreshClaim) => {
                    if(!disableRefreshClaim) {
                      getSelectedClaim(id)
                    }
                    setShowExternalAgent(false)
                  }}
                  onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                  onChangeClaimsHOC={onChangeClaimsHOC}
                  updateSelectedClaim={updateSelectedClaim}
                />
              } />
          )}
        </div>
      }
    />
  );
};

export default compose(ApptLetterHOC, ContactHOC)(UpdateClaimForm);
