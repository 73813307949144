import { useState } from "react";
import { MdFilterList } from "react-icons/md";

import EmptyState from "components/EmptyState";
import SVGIcon from "components/Icon";
import SvgIcon from "components/Icon/new";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import TablePagination from "components/NewPaginatedTable/Pagination";
import TableContent from "./Content";

import "stylesheets/components/pagination/index.scss";
import "stylesheets/components/table/index.scss";

const PaginatedTable = ({
  rowData,
  meta,
  searchParams,
  totalPages,
  can_update,
  can_destroy,

  getListAPI,
  onChangeSearchParams,
  onClickEdit,
  onClickDelete,
}) => {
  const [states, setStates] = useState({
    interval: 12,
    showSearchContent: false,
  });

  const { interval } = states;

  const renderTableContent = () => {
    if (rowData && rowData.length > 0) {
      return (
        <ul className={"at-email-templates__container no-gutters"}>
          {rowData.map((item) => (
            <TableContent
              key={item.id}
              data={item}
              can_update={can_update}
              can_destroy={can_destroy}
              onClickEdit={onClickEdit}
              onClickDelete={onClickDelete}
            />
          ))}
        </ul>
      );
    }

    return (
      <EmptyState
        title={"No Templates Available"}
        description={"Please create a template to show it here"}
        renderIcon={<SvgIcon svgHref={"icon-email-campaigns"} />}
      />
    );
  };

  const renderPaginationText = () => {
    return (
      <p className="at-table__pagination-info">
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        result{meta && meta.count !== 1 ? "s" : ""}
      </p>
    );
  };

  const renderTablePagination = () => {
    return (
      <div className={"at-pagination"}>
        <button
          className={`at-pagination__button at-pagination__button-arrow ${
            (meta && meta.page === 1) || !meta ? "disabled" : ""
          }`}
          onClick={() => {
            let tmp = "";
            searchParams.forEach((item) => {
              tmp += `q[${item.value}]=${item.param}&`;
            });
            getListAPI(1, tmp);
            setStates({
              ...states,
              interval: 12,
            });
          }}
        >
          <SVGIcon
            className={"at-pagination__arrow rotate-left"}
            svgHref={"icon-double-chevron"}
          />
        </button>

        <button
          className={`at-pagination__button at-pagination__button-arrow ${
            (meta && meta.page === 1) || !meta ? "disabled" : ""
          }`}
          onClick={() => {
            let tmp = "";
            searchParams.forEach((item) => {
              tmp += `q[${item.value}]=${item.param}&`;
            });
            getListAPI(meta.page - 1, tmp);
          }}
        >
          <SVGIcon
            className={"at-pagination__arrow rotate-left"}
            svgHref={"icon-chevron"}
          />
        </button>

        <button
          className={`at-pagination__button at-pagination__button-arrow ${
            interval <= 12 || !meta ? "disabled" : ""
          }`}
          onClick={() => {
            let tmp = interval;
            setStates({
              ...states,
              interval: tmp - 12,
            });
          }}
        >
          ...
        </button>

        {totalPages &&
          totalPages.map((item) => {
            if (item + 1 <= interval && item + 1 > interval - 12) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${meta && meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    searchParams.forEach((item) => {
                      tmp += `q[${item.value}]=${item.param}&`;
                    });
                    getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }

            return null;
          })}

        <button
          className={`at-pagination__button at-pagination__button-arrow ${
            (meta && interval >= meta.pages) || !meta ? "disabled" : ""
          }`}
          onClick={() => {
            let tmp = interval;
            setStates({
              ...states,
              interval: tmp + 12,
            });
          }}
        >
          ...
        </button>

        <button
          className={`at-pagination__button at-pagination__button-arrow ${
            (meta && meta.page === meta.pages) || !meta ? "disabled" : ""
          }`}
          onClick={() => {
            let tmp = "";
            searchParams.forEach((item) => {
              tmp += `q[${item.value}]=${item.param}&`;
            });
            getListAPI(meta.page + 1, tmp);
          }}
        >
          <SVGIcon
            className={"at-pagination__arrow rotate-right"}
            svgHref={"icon-chevron"}
          />
        </button>

        <button
          className={`at-pagination__button at-pagination__button-arrow ${
            (meta && meta.page === meta.pages) || !meta ? "disabled" : ""
          }`}
          onClick={() => {
            let tmp = "";
            searchParams.forEach((item) => {
              tmp += `q[${item.value}]=${item.param}&`;
            });
            getListAPI(meta.pages, tmp);
            setStates({
              ...states,
              interval: meta.pages,
            });
          }}
        >
          <SVGIcon
            className={"at-pagination__arrow rotate-right"}
            svgHref={"icon-double-chevron"}
          />
        </button>
      </div>
    );
  };

  return (
    <div className={"at-table__options"}>
      <div className={"mb-10"}>
        <button
          className={
            "btn-new btn-new--outline-secondary at-mobile-view__controller w-100"
          }
          onClick={() =>
            setStates({ showSearchContent: !states.showSearchContent })
          }
        >
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
            Filter & Search
          </h5>
        </button>

        <div className={"at-table__search-cont at-desktop-view__controller"}>
          <TableSearchParams
            mode={"desktop"}
            searchParams={searchParams}
            onChangeSearchParams={onChangeSearchParams}
            getListAPI={getListAPI}
          />
        </div>
      </div>

      {renderTableContent()}

      {meta && meta?.count > 0 && (
        <div className={`at-pagination__cont`}>
          <TablePagination
            meta={meta}
            searchParams={searchParams}
            getListAPI={getListAPI}
            totalPages={totalPages}
          />
          <p className={`at-table__pagination-info`}>
            Showing
            <span> {meta && meta.from} </span>-
            <span> {meta && meta.to} </span>
            of
            <span> {meta && meta.count} </span>
            results
          </p>
        </div>
      )}

      {states.showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={searchParams}
          onToggleSearchModal={() =>
            setStates({ showSearchContent: !states.showSearchContent })
          }
          onChangeSearchParams={onChangeSearchParams}
          getListAPI={getListAPI}
        />
      )}
    </div>
  );
};

export default PaginatedTable;
