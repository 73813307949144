import React, { Component } from "react";
import { connect } from "react-redux";
import _, { get } from "lodash";

import { edgePropDataMapping } from "dictionary/subsales";
import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put, Post } from "utils/axios";

const EditHOC = (WrappedComponent) => {
  class EditWrappedComponent extends Component {
    state = {
      descriptionType: "English",
      autoSuggestLoading: false,
      autoSuggestOptions: [],

      showEditModal: false,
      showLoadingModal: false,
      showGenerateModal: false,
    };

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeEditHOC = (val, context) => this.setState({ [context]: val });

    // update subsales details
    updateSubsales = (id, data) => {
      let temp = {
        currentPage: this.props.tmpSubsalesCurrentUser.meta.page,
        searchParams: this.props.query,
      };
      this.props.storeLastView(temp);
      Put(
        `/sub_sales/${id}`,
        data,
        this.updateSubsalesSuccess,
        this.updateSubsalesError,
        this.load,
      );
    };
    updateSubsalesSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.props.getSubsalesCurrentUser(currentPage, searchParams);
      this.props.getSelectedSubsales(payload.id);
      this.props.onChangeSubsalesHOC(false, "showNewModal");
      requestSuccess("Subsales record saved successfully.");
    };
    updateSubsalesError = (error) => requestError(error);

    edgeAutoSuggest = ({ query, selectedCategory, selectedPropertyType }) => {
      if (query && selectedCategory.id && selectedPropertyType.name) {
        Get(
          `/sub_sales/suggest_address?query=${query}&category_id=${selectedCategory.id}&property_type=${selectedPropertyType.name}`,
          this.edgeAutoSuggestSuccess,
          this.edgeAutoSuggestError,
          (load) => this.setState({ autoSuggestLoading: load }),
        );
      }
    };
    edgeAutoSuggestSuccess = (payload) => {
      this.setState({
        autoSuggestOptions: _.map(payload.data, (item) => {
          return {
            ...item,
            state_s_lower: _.find(edgePropDataMapping, { edgeProp: item.state_s_lower })?.atlas ?? item.state_s_lower,
            value: item.name_s_lower,
          };
        }),
      });
    };
    edgeAutoSuggestError = (error) => requestError(error);

    publishListing = (subsalesId, publishTypeId, val) =>
      Post(
        `/sub_sales/${subsalesId}/toggle_export?to_export=${val}&type_id=${publishTypeId}`,
        "",
        (payload) => this.publishListingSuccess(payload, val),
        this.publishListingError,
        this.load,
      );
    publishListingSuccess = (payload, val) => {
      requestSuccess(
        `Publication for the listing has been ${val ? "scheduled" : "removed"}.`,
      );
      this.props.getSelectedSubsales(payload.id);
    };
    publishListingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showGenerateModal={this.state.showGenerateModal}
            onLoadSubsalesEdit={this.state.showLoadingModal}
            descriptionType={this.state.descriptionType}
            autoSuggestOptions={this.state.autoSuggestOptions}
            onLoadEditSubsales={this.state.showLoadingModal}
            onChangeEditHOC={this.onChangeEditHOC}
            edgeAutoSuggest={this.edgeAutoSuggest}
            publishListing={this.publishListing}
            updateSubsales={this.updateSubsales}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps)(EditWrappedComponent);
};

export default EditHOC;
