import React, { Component } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { MdClose } from "react-icons/md";

import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "assets/translation";
import { requestError } from "utils/requestHandler";

import "stylesheets/components/upload/index.scss";
import "./index.scss";
import "./upload.scss";

const ImageTypeLibrary = [
  "jpeg",
  "jpg",
  "png",
  "webp",
  "tiff",
  "heic",
  "heif",
  "svg",
  "ico",
];

class Upload extends Component {
  state = {
    uploadedFiles: [],
    imageUrl: "",
  };

  fileInput = null;

  fileInputRef = (element) => {
    this.fileInput = element;
  };

  getMemorySize = (fileSize) => {
    return fileSize / 1000 > 1000
      ? `${(fileSize / 10 ** 6).toFixed(2)}MB`
      : `${(fileSize / 1000).toFixed(2)}KB`;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.files !== this.props.files) {
      this.setState({
        uploadedFiles: this.props.files,
      });
    }
    return true;
  }

  onUploadImage(event) {
    let sourceFiles = event.target.files;
    let tempFiles = _.cloneDeep(this.state.uploadedFiles);

    if (sourceFiles && sourceFiles.length > 0) {
      Object.keys(sourceFiles).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let temp = {};
          if (
            sourceFiles[key].type.includes("image") &&
            this.props.imageRestriction
          ) {
            let image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              if (image.height > 640 || image.width > 480) {
                temp = {
                  name: sourceFiles[key].name,
                  source: e.target.result,
                  changeImage: true,
                  size: sourceFiles[key].size,
                  type: sourceFiles[key].type,
                };
                tempFiles.push(temp);
                this.setState(
                  {
                    uploadedFiles: tempFiles,
                  },
                  () => {
                    this.props.uploadImage && this.props.uploadImage(tempFiles);
                  }
                );
              } else {
                requestError("Image size is too small");
              }
            };
          } else {
            temp = {
              name: sourceFiles[key].name,
              source: e.target.result,
              changeImage: true,
              size: sourceFiles[key].size,
              type: sourceFiles[key].type,
            };
            tempFiles.push(temp);
            this.setState(
              {
                uploadedFiles: tempFiles,
              },
              () => {
                this.props.uploadImage && this.props.uploadImage(tempFiles);
              }
            );
          }
        };
        reader.readAsDataURL(sourceFiles[key]);
      });
    }
  }

  renderRequired = (param) => {
    if (param) {
      return (
        <div
          className="at-form-input__required position-static"
          style={{ textAlign: "right" }}
        >
          {getTranslation('required', this.props.language)}
        </div>
      );
    }
  };

  onClickRemoveFile = (item, index) => {
    if (this.props.onRemoveFile) {
      this.props.onRemoveFile(item, index);
    } else {
      let tmpFiles = _.cloneDeep(this.state.uploadedFiles);
      tmpFiles.splice(index, 1);
      this.setState(
        {
          uploadedFiles: tmpFiles,
        },
        () => {
          const tmpValue =
            tmpFiles.length !== 0 ? tmpFiles : [{ source: "", name: "" }];
          this.props.uploadImage && this.props.uploadImage(tmpValue);
        }
      );
    }
  };

  render = () => {
    const {
      hideLabelDragDrop,
      required = false,
      multiple = true,
      containerClass = "",
      containerStyle,
      style,
      disabled,
      accepts,
      labelText,
      hidePreview,
    } = this.props;

    return (
      <div
        className={`at-input_upload-cont ${containerClass}`}
        style={{
          textAlign: "center",
          ...containerStyle,
        }}
      >
        {((!multiple && this.state.uploadedFiles.length === 0) || multiple) && (
          <div
            className={`at-input_uploader-cont ${this.state.uploadedFiles.length > 0 ? "at-file_uploaded" : ""}`}
            style={{ ...style }}
          >
            <AtlasIcon svgHref={"atlas-document-upload"} />
            <label className="at-input_upload-label">{!hideLabelDragDrop ? 'or drag and drop' : ""}</label>
            {labelText && <p className="fs-2 fw-400">{labelText}</p>}
            <input
              className={"at-input_uploader-file at-input_upload-file"}
              multiple={multiple}
              disabled={disabled}
              id={"fileInputID"}
              ref={this.fileInputRef}
              type={"file"}
              onClick={() =>
                (document.getElementById("fileInputID").value = "")
              }
              onChange={(event) => this.onUploadImage(event)}
              accept={
                accepts || "application/pdf,image/png,image/jpg,image/jpeg"
              }
            />
          </div>
        )}
        {this.state.uploadedFiles.length === 0 && this.renderRequired(required)}
        {!hidePreview && (
          <Grid container className="at-file_item-files_cont">
            {this.state.uploadedFiles.map((item, index) => {
              const isImage =
                typeof item.source === "string"
                  ? ImageTypeLibrary.some(
                      (imgTypeItem) =>
                        item.source
                          .toLowerCase()
                          .includes(`data:image/${imgTypeItem}`) ||
                        item.source.toLowerCase().includes(`.${imgTypeItem}`)
                    )
                  : false;
              return (
                item.source && (
                  <Grid
                    item
                    key={`at-file_item-${index}`}
                    xs={12}
                    className={`at-file_item-cont mb-1`}
                    style={{ ...(isImage && { height: 240 }) }}
                  >
                    <div className="at-file_item-overlay" />
                    <div className="at-file_item-action">
                      <button
                        className="at-file_item-btn_close"
                        onClick={() => this.onClickRemoveFile(item, index)}
                      >
                        <MdClose />
                      </button>
                      <div>
                        <p className="at-file_item-file_name">{item.name}</p>
                        {item.size && (
                          <p className="fs-2">
                            {this.getMemorySize(item.size)}
                          </p>
                        )}
                      </div>
                      <div className="at-file_item-action-overlay"></div>
                    </div>
                    {isImage && <img src={item.source} />}
                  </Grid>
                )
              );
            })}
          </Grid>
        )}
      </div>
    );
  };
}

export default Upload;
