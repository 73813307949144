import React from "react";

import AtlasButton from "components/Button";
import Form from "../components/Form";

import isEmptyValue from "utils/isEmpty";

const Setup = ({
  categories,
  selectedEmailTemplate,
  onChangeEmailTemplatesHOC,
  updateEmailTemplate,
}) => {
  const handleClose = () => {
    onChangeEmailTemplatesHOC("emailTemplateDialog", "");
  };

  const handleChange = (key, val) => {
    onChangeEmailTemplatesHOC("selectedEmailTemplate", {
      ...selectedEmailTemplate,
      [key]: val,
    });
  };

  const handleUpdate = () => {
    const {
      attachment,
      categories,
      content,
      html_code,
      name,
      status_id,
      subject,
    } = selectedEmailTemplate;

    const formData = new FormData();

    formData.append("name", name.trim());
    formData.append("type_id", 1);
    formData.append("status_id", parseInt(status_id));
    formData.append("html_code", html_code);

    if (!isEmptyValue(attachment)) {
      if (attachment && attachment.url) {
        formData.append("new_attachment", false);
      } else {
        formData.append("new_attachment", true);
        formData.append("attachment", attachment);
      }
    } else {
      formData.append("new_attachment", true);
      formData.append("attachment", "");
    }

    formData.append("content[body]", JSON.stringify(content));
    formData.append("extra[subject]", subject.trim());

    for (const category of categories) {
      formData.append(
        "sort_list[]",
        category && category.customOption ? category.label : category,
      );
    }

    updateEmailTemplate(formData, "setup");
  };

  return (
    <div className={"at-email-templates__form__card"}>
      <Form
        values={selectedEmailTemplate}
        categories={categories}
        attachment={selectedEmailTemplate.attachment}
        context={"edit"}
        handleChange={handleChange}
      />

      <div className="at-modal_dialog-container-footer g-3">
        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--success "}
          onClick={handleUpdate}
          children={"Update"}
          disabled={selectedEmailTemplate.name === "" || selectedEmailTemplate.subject === ""}
        />
        <AtlasButton
          type={"button"}
          onClick={() => onChangeEmailTemplatesHOC("showUpdateModal", false)}
          children={'Close'}
          className={"btn-new btn-new--outline-secondary"}
        />
      </div>
    </div>
  );
};

export default Setup;
