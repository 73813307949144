import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Moment from "moment";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";


const CustomBtnDatePicker = ({
  disabled,
  containerClass,
  onAccept,
  value,
  onChange,
}) => {
  const [open, onOpen] = useState(false);

  return (
    <>
      <CustomButton
        disabled={disabled}
        isIconButton={true}
        containerClass={`at-table-icon-button ml-3 ${containerClass}`}
        containerStyle={{minWidth: 40, height: 40}}
        onClickContainer={() => onOpen(true)}>
        <AtlasIcon style={{minWidth: 20, width: 20, fill: "#3B82F6"}} svgHref={"atlas-calendar"} />
      </CustomButton>
      <LocalizationProvider 
        dateAdapter={AdapterMoment}
        adapterLocale="en"
        moment={Moment}>
        <MobileDatePicker
          className="d-none"
          open={open}
          disabled={disabled}
          onAccept={val=> {
              onAccept(val)
              onOpen(false)
          }}
          onClose={val=> {
              onOpen(false)
          }}
          value={value}
          onChange={onChange}
        />
      </LocalizationProvider>
    </>
  )
}

export default CustomBtnDatePicker;