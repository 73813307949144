import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import LoadingModal from "components/Modal/loading";
import ConfirmationModal from "components/Modal/confirmation";

import { Get, Put } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { getAllDictionaryData } from "actions/dictionary";
import { getTranslation } from "../assets";

const WelcomeModalTemplateHOC = (WrappedComponent) => {
  class WelcomeModalTemplateWrappedComponent extends Component {
    state = {
      loading: false,
      showLoadingModal: false,

      updateNewAgent: {},
      branch_option: [],
      currentModalSequence: 0,

      showConfirmationModal: false,
      showConfirmationModalTitle: "",
      showConfirmationModalMode: "",
      showConfirmationModalMessage: "",
      onClickConfirmationModalButton: () => {},
    };

    componentDidMount = () => {
      const {
        country_id,
        branch_id,
        agent_registration_id,
        name_card,
        referrer_id,
        team_id,
        display_name,
        country_name,
        identification_number,
      } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      // Codes below removed reason support team request to allow agent to update their own dob.
      // Keep it here for future reference.
      // let tempDate = null;
      // if (identification_number && identification_number.length > 6) {
      //   let tempNRICYear = identification_number.substring(0, 2);
      //   let tempYear = "20" + tempNRICYear;
      //   if (parseInt(tempYear) > Moment().year()) {
      //     tempYear = "19" + tempNRICYear;
      //   }
      //   tempDate =
      //     identification_number.substring(6, 4) +
      //     "/" +
      //     identification_number.substring(2, 4) +
      //     "/" +
      //     tempYear;
      // }

      let temp =
        country_name === "Malaysia"
          ? {
              bank_id: 0,
              branch_id: branch_id,
              bank_account_number: "",
              bank_owner_name: "",
              income_tax_number: "",
              agent_registration_attributes: {
                id: agent_registration_id || null,
                direct_leader_id: referrer_id || "",
                has_been_agent: false,
                emergency_person_name: "",
                emergency_contact_number: "",
                ic_front_file_name: "",
                ic_back_file_name: "",
                bank_account_statement_file_name: "",
                ssm_copy_file_name: "",
                ic_front: null,
                ic_back: null,
                bank_account_statement: null,
                ssm_copy: null,
                resignation_letter_file_name: "",
                previous_company: "",
                previous_company_job_title: "",
                resignation_letter: null,
                is_undischarged_bankrupt_person: "",
                has_been_convicted_crime: "",
              },
              name_card_attributes: {
                id: name_card.id || null,
                name_card_display_name: display_name,
                include_profile_photo: false,
              },
            }
          : {
              branch_id: branch_id,
              avatar: null,
              avatar_file_name: "",
              agent_registration_attributes: {
                id: agent_registration_id || null,
                direct_leader_id: referrer_id || "",
                ic_front_file_name: "",
                ic_front: null,
                is_undischarged_bankrupt_person: "",
                has_been_convicted_crime: "",
              },
            };
      this.setState({
        updateNewAgent: {
          country_id: country_id,
          date_of_birth: "",
          team_id: team_id,
          gender_id: 1,
          marital_status_id: 1,
          current_address: "",
          display_name: "",
          ...temp,
        },
      });
    };

    load = (param) => this.setState({ loading: param });

    submitLoad = (param) => this.setState({ showLoadingModal: param });

    onChangeProfileHOC = (val, context) => this.setState({ [context]: val });

    onChangeField = (val, stateName) => {
      let tmp = _.cloneDeep(this.state.updateNewAgent);
      tmp[stateName] = val;
      return this.setState({ updateNewAgent: tmp });
    };

    onClickNextModalSequence = () => 
      this.setState((prevState) => ({
        currentModalSequence: prevState.currentModalSequence + 1,
      }));

    onClickBackModalSequence = () =>
      this.setState((prevState) => ({
        currentModalSequence: prevState.currentModalSequence - 1,
      }));

    onClickSubmitUpdateData = () => {
      let temp = _.cloneDeep(this.state.updateNewAgent);
      temp.date_of_birth = Moment(temp.date_of_birth).format("YYYY-MM-DD");

      if (temp.current_address_1 || temp.current_address_2 || temp.current_address_3 || temp.current_address_4) {
        temp.current_address  = [temp.current_address_1, temp.current_address_2, temp.current_address_3, temp.current_address_4].filter(Boolean).join(", ");
      }

      if (temp.bank_id) {
        delete temp.agent_registration_attributes.new_bank_name;
      }

      Put(
        `/registrations/update`,
        temp,
        this.onClickSubmitUpdateDataSuccess,
        this.onClickSubmitUpdateDataError,
        this.submitLoad
      );
    };
    onClickSubmitUpdateDataSuccess = (payload) => {
      const { country_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      this.setState({
        showConfirmationModal: true,
        showConfirmationModalTitle: getTranslation("successfull", country_id === 14 ? "jp" : "en"),
        showConfirmationModalMode: "check",
        showConfirmationModalMessage: payload.message,
        onClickConfirmationModalButton: () => {
          this.setState({ showConfirmationModal: false });
          window.location.reload();
        },
      });
    }
    onClickSubmitUpdateDataError = (error) => requestError(error);

    getBranchInfo = (id) =>
      Get(
        `/branches?[q][country_id_eq]=${id}`,
        this.getBranchInfoSuccess,
        this.getBranchInfoError,
        this.load
      );
    getBranchInfoSuccess = (payload) =>
      this.setState({ branch_option: payload });
    getBranchInfoError = (error) => requestError(error);

    render = () => {
      const { country_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      return (
        <>
          <WrappedComponent
            {...this.props}
            branch_option={this.state.branch_option}
            currentModalSequence={this.state.currentModalSequence}
            updateNewAgent={this.state.updateNewAgent}
            subsale_training={this.state.subsale_training}
            onChangeProfileHOC={this.onChangeProfileHOC}
            onChangeField={this.onChangeField}
            getBranchInfo={this.getBranchInfo}
            onClickSubmitUpdateData={this.onClickSubmitUpdateData}
            onClickBackModalSequence={this.onClickBackModalSequence}
            onClickNextModalSequence={this.onClickNextModalSequence}
          />

          <ConfirmationModal
            open={this.state.showConfirmationModal}
            title={this.state.showConfirmationModalTitle}
            message={
              this.state.showConfirmationModalMessage ||
              "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            mode={this.state.showConfirmationModalMode}
            positiveText={getTranslation("ok", country_id === 14 ? "jp" : "en")}
            positiveAction={this.state.onClickConfirmationModalButton}
            hideNegative
          />

          <LoadingModal
            isOpen={this.state.showLoadingModal}
            message={getTranslation("agent.profile.loading_message", country_id === 14 ? "jp" : "en")}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { getAllDictionaryData })(
    WelcomeModalTemplateWrappedComponent
  );
};

export default WelcomeModalTemplateHOC;
