import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { IoIosClose } from "react-icons/io";

import DatePicker from "react-datepicker";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import CustomCheckbox from "components/Checkbox";
import CustomBtnDatePicker from "components/Button/datepicker"
import ModalDialog from "components/Modal/ModalDialog";
import AtlasIcon from "components/Icon/atlasIcon";

import TenancyAgreementHOC from "./actions";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const headerData = ({
  can_update,
  showDatePicker, 
  onShowDatePicker,
  onCopy,
  updateTenancyAgreement
}) => [
  { header: "Form No.", 
    renderColumn: (rowData, index) => {
      return (
        <> 
          <p>{rowData.confirmation_form_number}</p>
          {
            rowData.unit_id && (
              <DarkTooltip
                enterTouchDelay={50}
                classes={{ tooltip: "tooltip-arrow top" }}
                placement={"top"}
                title={"Unit ID"}>
                <span
                  className="badge badge-primary copy_unitID-clipboard d-block mt-10 mb-10"
                  data-clipboard-text={rowData.unit_id}
                  onClick={() => {
                    navigator.clipboard.writeText(rowData.unit_id);
                    onCopy()
                  }}
                  style={{
                    fontSize: "0.75rem",
                    width: "max-content",
                    cursor: "pointer",
                  }}>
                  {rowData.unit_id}
                </span>
              </DarkTooltip>
            )
          }
        </>
      )
    } 
  },
  {
    header: "Law Firm",
    accessor: "law_firm_name"  
  },
  { header: "Rental Price", accessor: "net_price", columnStyle: { width: "60%" } },
  { header: "Fee", accessor: "fee", columnStyle: { width: "60%" } },
  {
    header: "Claim Approval Date",
    accessor: "claim_approved_date",
  },
  { header: "Agreement Date", accessor: "agreement_date" },
  { 
    header: "Billed", 
    columnStyle: { width: "60%" },
    renderColumn: (rowData) => {
      return (
        <>
          <CustomCheckbox
            disabled={!can_update}
            checked={rowData.is_billed}
            onChangeCheckboxValue={() => {
              if(!rowData.is_billed) {
                onShowDatePicker(!showDatePicker)
              } else {
                updateTenancyAgreement(rowData.id, { is_billed: !rowData.is_billed })
              }
            }}
          />
          <LocalizationProvider 
            dateAdapter={AdapterMoment}
            adapterLocale="en"
            moment={Moment}>
            <MobileDatePicker
              disabled={!can_update}
              className={"d-none"}
              open={showDatePicker}
              onAccept={ val => {
                updateTenancyAgreement(rowData.id, {
                is_billed: true,
                billing_date: val ? val.format("YYYY-MM-DD") : "" 
              })}}
              onClose={ () => onShowDatePicker(!showDatePicker)}
            />
          </LocalizationProvider>
        </>
      );
    }, 
  },
  { header: "Billing Date",
    renderColumn: (rowData) => {
      return (
        <div className="d-flex align-items-center">
          <span>{rowData.billing_date || "-"}</span>
          <CustomBtnDatePicker
            disabled={!can_update}
            containerClass={!rowData.is_billed ? "d-none" : "" }
            onAccept={ val => updateTenancyAgreement(rowData.id, {
              is_billed: true,
              billing_date: val ? val.format("YYYY-MM-DD") : "" 
            })
          }/>
        </div>
      )
    }, 
   },
];

class TenancyAgreementManagement extends Component {
  state = {
    showDatePicker: false,
    copyStatus: false,
  }
  componentDidMount = () => {
    initGA("/dashboard/admin/tenancy-agreements");

    this.props.getTenancyAgreementList(1, "");
  };

  renderSnackBar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={this.state.copyStatus}
      autoHideDuration={6000}
      onClose={() => this.setState({ copyStatus: !this.state.copyStatus })}>
      <SnackbarContent
        message={
          <span>Data copied into clipboard. You can now paste the data.</span>
        }
        action={
          <button
            onClick={() =>
              this.setState({ copyStatus: !this.state.copyStatus })
            }>
            <IoIosClose style={{ color: "#eee", width: 25, height: 25 }} />
          </button>
        }
      />
    </Snackbar>
  );

  render = () => {
    const { can_update } = permissionsChecker("Tenancy Agreements", this.props.data);
    
    return (
      <>
        <ModuleHeader
          title={"Tenancy Agreements"}
          atlasIcon={"svg-react-clipboard"}
          actionButton={[
            {
              label: "Export",
              onShow: true,
              className: "btn-new btn-new--outline-secondary",
              icon: (
                <AtlasIcon svgHref={"atlas-xlsx-file"} style={{ width: 20, height: 20 }}/>
              ),
              onClick: () => this.props.onChangeTenancyAgreementHOC(true, "showExportDialog"),
              renderButton: () => (
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() => {}}>
                  <AtlasIcon
                    svgHref={"atlas-xlsx-file"}
                    style={{ width: 20, height: 20 }}
                  />
                  Export
                </button>
              )
            },]}/>
        <CustomCard
          cardContent={
            <AtPaginatedTable
              columns={headerData({
                can_update,
                showDatePicker: this.state.showDatePicker,
                updateTenancyAgreement: this.props.updateTenancyAgreement,
                onCopy: () => this.setState({ copyStatus: true }),
                onShowDatePicker: val => this.setState({ showDatePicker: val }),
              })}
              rowData={this.props.tenancyAgreementsList.data || []}
              meta={this.props.tenancyAgreementsList.meta && this.props.tenancyAgreementsList.meta}
              searchParams={this.props.searchParams || []}
              onChangeSearchParams={(val) => this.props.onChangeTenancyAgreementHOC(val, "searchParams")}
              getListAPI={(page, search) => this.props.getTenancyAgreementList(page, search)}
              totalPages={this.props.totalPages}
            />
          }
        />
        {
          this.props.showExportDialog && (
            <ModalDialog
              title={"Export Tenancy Agreements"}
              containerClasses={{paperScrollPaper: "at-modal_dialog-overflow_visible"}}
              responsiveSize="sm"
              contentStyle={{height: 240}}
              onClose={() => this.props.onCloseExportModal()}
              children={
                <div className="grid-control">
                  <div className="grid-half-col">
                    <label label className={"at-form-input__title"} required>
                      {"Start Date"}
                    </label>
                    <div className="at-form-input">
                      <AtlasIcon
                        className={"at-form-input__calendar"}
                        svgHref={"atlas-calendar-input"}
                      />
                      <DatePicker
                        placeholderText="Select start date"
                        showYearDropdown={true}
                        className="at-form-input__input"
                        dateFormat="YYYY-MM-DD"
                        value={this.props.exportParams.start_date || null}
                        onChange={(val) => this.props.onChangeTenancyAgreementHOC({
                          ...this.props.exportParams,
                          start_date: Moment(val).format("YYYY-MM-DD")
                        }, "exportParams")
                      }/>
                    </div>
                  </div>
                  <div className="grid-half-col">
                    <label className={"at-form-input__title"} required>
                      {"End Date"}
                    </label>
                    <div className="at-form-input">
                      <AtlasIcon
                        className={"at-form-input__calendar"}
                        svgHref={"atlas-calendar-input"}
                      />
                      <DatePicker
                        placeholderText="Select end date"
                        showYearDropdown={true}
                        className="at-form-input__input"
                        dateFormat="YYYY-MM-DD"
                        value={this.props.exportParams.end_date || null}
                        onChange={(val) => this.props.onChangeTenancyAgreementHOC({
                          ...this.props.exportParams,
                          end_date: Moment(val).format("YYYY-MM-DD")
                        }, "exportParams")
                      }/>
                    </div>
                  </div>
                </div>
              }
              footer={
                <div className="d-flex g-3">
                  <button
                    className="btn-new btn-new--primary"
                    onClick={() => this.props.exportTenancyAgreement(this.props.exportParams)}>
                    Download
                  </button>
                  <button
                    type={"button"}
                    className="btn-new btn-new--outline-secondary"
                    onClick={() => this.props.onCloseExportModal()}>
                    Cancel
                  </button>
                </div>
              }/>
          )
        }
        {this.renderSnackBar()}
        {(this.props.onLoad || this.props.onLoadUpdate) && <LoadingModal />}
      </>
    );
  };
}

export default compose(TenancyAgreementHOC)(TenancyAgreementManagement);
