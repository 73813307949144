import { useEffect, useState } from "react";
import _ from "lodash";

import AtlasButton from "components/Button";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleFormHeader from "components/Form/header";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import ConfirmationDialog from "components/Modal/confirmation";

import Content from "./Content";
import From from "./From";
import PreviewAndTest from "./PreviewAndTest";
import ScheduleDialog from "./Schedule";
import Subject from "./Subject";
import To from "./To";

const Update = ({
  onLoadEmailCampaigns,
  selectedEmailCampaign,
  originalEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign
}) => {
  const [dialog, setDialog] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [activePanel, setActivePanel] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);

  const handleClose = () => {
    setDialog("");
    onChangeEmailCampaignsHOC("showUpdateModal", false);
    onChangeEmailCampaignsHOC("selectedEmailCampaign", {});
    onChangeEmailCampaignsHOC("originalEmailCampaign", {});
  };

  const allStepsCompleted = [
    "name",
    "sender_email",
    "sender_name",
    "email_list_ids",
    "subject",
    "preview_text",
    "email_template_id",
  ].every((value) => selectedEmailCampaign[value]);

  const renderScheduleDialog = () => (
    <ScheduleDialog
      handleClose={() => setDialog("")}
      handleUpdateDialogClose={handleClose}
      selectedEmailCampaign={selectedEmailCampaign}
      updateEmailCampaign={updateEmailCampaign}
    />
  );

  const renderPreviewDialog = () => (
    <AtlasDialog open={dialog === "preview"}>
      <CustomCard
        cardContent={
          <PreviewAndTest
            handleClose={() => setDialog("")}
            selectedEmailCampaign={selectedEmailCampaign}
          />
        }
      />
    </AtlasDialog>
  );

  const renderExitDialog = () => (
    <ConfirmationDialog
      open={showConfirmationDialog}
      title={"Exit"}
      message={"Please make sure you have saved all the changes before exit."}
      positiveAction={handleClose}
      negativeAction={() => setShowConfirmationDialog(false)}
      positiveText={"Exit"}
      negativeText={"Cancel"}
    />
  );

  const panelProps = {
    checkedArray,
    setCheckedArray,
    handleAccordionChange: (panel) => () => setActivePanel(panel),
    handleAccordionClose: () => setActivePanel(""),
    selectedEmailCampaign,
    onChangeEmailCampaignsHOC,
    updateEmailCampaign,
  };

  return (
    <ModalDialog
      title={"Edit Email Campaign"}
      onLoad={onLoadEmailCampaigns}
      fullWidth={true}
      fullHeight={true}
      onClose={handleClose}
      children={
        <>
          <div className="at-form__content">
            <ModuleFormHeader
              title={"Details"}
              moduleIcon={"atlas-note"} />
            <h2 className="at-form-input__title mt-3" required>Campaign Name</h2>
            <CustomFormInput
              type={"text"}
              required={true}
              value={selectedEmailCampaign.name || ""}
              onChangeValue={(val) => onChangeEmailCampaignsHOC("selectedEmailCampaign", {...selectedEmailCampaign, name: val})}
              maxLength={128}
            />
            <small className={"position-relative form-text text-muted"}>
              {selectedEmailCampaign.name ? selectedEmailCampaign.name.length : 0} /128
            </small>
          </div>
          <div className="at-form__content">
            <ModuleFormHeader
              title={"Sender Details"}
              moduleIcon={"atlas-edit-2"} />
            <From {...panelProps} />
          </div>
          <div className="at-form__content">
            <To {...panelProps} />
          </div>
          <div className="at-form__content">
            <Content {...panelProps} />
          </div>

          {dialog === "schedule" && renderScheduleDialog()}
          {renderPreviewDialog()}
          {renderExitDialog()}
        </>
      }
      footer={
        <div className={"d-flex flex-wrap g-3"}>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--primary"}
            disabled={!allStepsCompleted}
            onClick={() => setDialog("schedule")}
            children={"Schedule"}
          />
          <AtlasButton
            type={"button"}
            children={'Update'}
            className={"btn-new btn-new--success"}
            onClick={() => updateEmailCampaign({...selectedEmailCampaign, email_list_ids: checkedArray, status: 1})}
          />
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--secondary"}
            disabled={!allStepsCompleted}
            onClick={() => setDialog("preview")}
            children={"Preview & Test"}
          />
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--outline-secondary"}
            onClick={() => !_.isEqual(originalEmailCampaign, selectedEmailCampaign) 
              ? setShowConfirmationDialog(true)
              : handleClose()
            }
            children={"Close"}
          />
        </div>
      } />
  );
};

export default Update;
